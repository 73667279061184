import { TranslateService } from '@ngx-translate/core';
import { PermissionEnum } from './../../../enums/permission-enum';
import { FormControlsService } from './../../form-controls.service';
import { TranslationEnum } from './../../../enums/translation-enum';
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { Subscription } from 'rxjs';
import { TresciService } from 'src/app/tresci.service';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit, OnDestroy {

  @Input() label?: string;
  @Input() notAffectOnOtherControl = '';
  @Input() fileInfoId?= '';
  @Output() closeEvent = new EventEmitter<void>();
  private subscriptions: Subscription[] = [];

  TranslationEnum = TranslationEnum;
  PermissionEnum = PermissionEnum;
  ImgSrc: SafeUrl = '';
  Exists = false;
  public PermissionValue = false;
  wczytane: boolean = false;

  constructor(private formControlsService: FormControlsService, private translationService: TranslateService,
    private authorizationService: AuthorizationService, private cdRef: ChangeDetectorRef, public tresci: TresciService) {
    const permission = this.authorizationService.hasPermission(PermissionEnum.Translation);


    if (permission !== null && permission !== undefined) {
      this.PermissionValue = permission.Value;
      this.Exists = permission.Value;
    } else {
      this.Exists = false;
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnInit(): void {
    // console.log("info ", this.fileInfoId);
    if (this.fileInfoId !== undefined) {
      // this.Exists = true;
      // this.getImg();
    }
    // console.log("wtf", this.label.toLowerCase());
    this.subscriptions.push(this.translationService.get('info_' + this.label.toLowerCase()).subscribe(x => {
      if (x.trim().toLowerCase() !== ('info_' + this.label.toLowerCase())) {
        this.Exists = true;
      }
    }));

  }

  mouseenter(): void {
    // console.log("info 2", this.fileInfoId);
    if (this.Exists && !this.tresci.wczytywanie && !this.wczytane) {
     //  // console.log("info 3", this.fileInfoId);
      this.getImg();
      this.Exists = true;
    }
  }


  getImg(): void {
    this.tresci.wczytywanie = true;
    // console.log("label????", this.label, this.fileInfoId);
    //this.subscriptions.push(this.formControlsService.getImgForInfo(this.fileInfoId ?? 'plik_info_' + this.label + '_' + (window as any)['site'] + '_' + (window as any)['culture']).subscribe(
    this.subscriptions.push(this.formControlsService.getImgForInfo('plik_info_' + this.label + '_' + (window as any)['site'] + '_' + (window as any)['culture']).subscribe(
      (data) => {
        this.createImageFromBlob(data);
        this.tresci.wczytywanie = false;
        this.wczytane = true;
      },
      (error) => {
        this.ImgSrc = undefined;
        this.tresci.wczytywanie = false;
      }
    ));

  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.ImgSrc = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  close() {
    this.closeEvent.emit();
  }

}
