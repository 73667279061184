import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Control } from 'src/app/form-v2/form-model/Control';
import { Pergola } from 'src/app/form-v2/form-model/Pergola';
import { Slup } from 'src/app/form-v2/form-model/Slup';
import { DynamicFormComponent } from 'src/app/new-form/initial-form/dynamic-form/dynamic-form.component';
import { SystemEnum } from 'src/app/shared/enums/system-enum';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edytuj-slup',
  templateUrl: './edytuj-slup.component.html',
  styleUrls: ['./edytuj-slup.component.scss']
})
export class EdytujSlupComponent implements OnInit {
  pergola: Pergola;
  public TranslationEnum = TranslationEnum;
  form:FormGroup;
  formSlup:FormGroup;
  formOdw:FormGroup;
  formReg:FormGroup;
  public server_url = environment.apiUrl;
  public ListaSlup = ['SLUP'];
  public ListaOdwodnienie = ['TAK', 'NIE'];
  public system;
  constructor(public dialogRef: MatDialogRef<DynamicFormComponent>, @Inject(MAT_DIALOG_DATA) public data:Pergola) {
    this.pergola = data;
    if(localStorage.site == 'stale'){
      this.system = SystemEnum[window['site']];
     } else {
      this.system = SystemEnum[localStorage.site];
     }
    //this.pergola.edytuj
   }

  ngOnInit(): void {
    this.form = new FormGroup({
      state: new FormControl({value: "x", disabled: false}),
    });
    this.formSlup = new FormGroup({
      state: new FormControl({value: "x", disabled: false}),
    });
    this.formOdw = new FormGroup({
      state: new FormControl({value: "x", disabled: false}),
    });
    this.formReg = new FormGroup({
      state: new FormControl({value: "x", disabled: false}),
    });

    
    if(this.pergola.slupy[this.pergola.edytuj].czy_mozliwy_uchwyt || this.pergola.OpcjeZaawansowane == "TAK"){
       this.ListaSlup.push('UCHWYT');
    }
    if(this.pergola.slupy[this.pergola.edytuj].ktory_rog == 0 || this.pergola.OpcjeZaawansowane == "TAK"){
       this.ListaSlup.push('BRAK');
    }
    // console.log("typ", typeof  this.pergola.slupy[this.pergola.edytuj].czyinnawys);
  
  }
  sprawdz(slup:Slup) {
    slup.ustaw(this.pergola, this.pergola.edytuj);
    // console.log(event);
    slup.ustaw_wym();
    this.pergola.JakiWymiar();
    
  }
  usunSlup(){
    this.pergola.usunSlup(this.pergola.edytuj);
  
    
    this.pergola.slupy.forEach(s => {
      s.ustaw_wym();
    });
    this.pergola.JakiWymiar();
    this.dialogRef.close();
  }
  changeOdw(event:any){
    // console.log("eventO", event);
    this.pergola.slupy[this.pergola.edytuj].czy_odwodnienie =  event.value;
    
  }
  changeReg(event:any){
    // console.log("eventR", event);
    this.pergola.slupy[this.pergola.edytuj].czy_stopa_regulowana =  event;
    
  }
  changeSelect(event:any){
    // console.log("event", event);
    this.pergola.slupy[this.pergola.edytuj].stopa =  event.value;
    
  }
  changeCheck(event:any){
    // console.log("eventC", event);
    //this.pergola.slupy[this.pergola.edytuj].czyinnawys =  event.value;
    
  }
  test(){
    // console.log("test", this.pergola.slupy[this.pergola.edytuj].czyinnawys);
  }
  changeCheckPre(){
    if(!this.pergola.slupy[this.pergola.edytuj].klient_odwodnienie && this.pergola.slupy[this.pergola.edytuj].pre_czy_odwodnienie != ''){
      this.pergola.slupy[this.pergola.edytuj].czy_odwodnienie = this.pergola.slupy[this.pergola.edytuj].pre_czy_odwodnienie;
    }
    if(!this.pergola.slupy[this.pergola.edytuj].klient_rodzaj && this.pergola.slupy[this.pergola.edytuj].pre_rodzaj != ''){
      this.pergola.slupy[this.pergola.edytuj].rodzaj = this.pergola.slupy[this.pergola.edytuj].pre_rodzaj;
      this.pergola.slupy[this.pergola.edytuj].czy_widoczny = this.pergola.slupy[this.pergola.edytuj].pre_czy_widoczny;
      this.pergola.slupy[this.pergola.edytuj].czy_uchwyt = this.pergola.slupy[this.pergola.edytuj].pre_czy_uchwyt;

    }
    if(!this.pergola.slupy[this.pergola.edytuj].klient_stopa && this.pergola.slupy[this.pergola.edytuj].pre_stopa != ''){
      this.pergola.slupy[this.pergola.edytuj].stopa = this.pergola.slupy[this.pergola.edytuj].pre_stopa;
    }
    if(!this.pergola.slupy[this.pergola.edytuj].klient_stopa_regulowana && this.pergola.slupy[this.pergola.edytuj].pre_stopa_reg != ''){
      this.pergola.slupy[this.pergola.edytuj].czy_stopa_regulowana = this.pergola.slupy[this.pergola.edytuj].pre_stopa_reg;
    }
  }
  changeSelectSlup(event:any){
    // console.log("event1", event);
    this.pergola.slupy[this.pergola.edytuj].rodzaj =  event.value;
    if(event.value == 'BRAK'){
      this.pergola.usunSlup(this.pergola.edytuj);
  
    
      this.pergola.slupy.forEach(s => {
        s.ustaw_wym();
      });
      this.pergola.JakiWymiar();
    } else if(event.value == 'SLUP'){
      this.pergola.przywrocSlup(this.pergola.edytuj);
      this.pergola.slupy.forEach(s => {
        s.ustaw_wym();
      });
      this.pergola.JakiWymiar();
    } else if(event.value == 'UCHWYT'){
      this.pergola.ustawUchwyt(this.pergola.edytuj);
      this.pergola.slupy.forEach(s => {
        s.ustaw_wym();
      });
      this.pergola.JakiWymiar();
    }
    
  }

  
}
