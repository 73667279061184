import { Component, AfterViewInit, OnDestroy, ChangeDetectorRef, } from '@angular/core';
import { HttpRequest, HttpEvent, HttpEventType, HttpClient } from '@angular/common/http';
import { TresciService } from './../tresci.service';
import { ActivatedRoute } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { ICountry } from '../pomocnicze/Interfaces/ICountry';
import { ClientInfo, ContractorClientProfitMargin } from '../shared/models/client/client-info.model';
import { TranslationEnum } from '../shared/enums/translation-enum';
import { Observable, Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { FormService } from '../shared/service/form.service';
// import { ClientVm, ContractorClientProfitMarginsVm } from './viewModels/IClientVm';



enum KindOper {
  EditClient = 'editClient',
  PreviewClient = 'previewClient',
  AddClient = 'addClient',
  EditAccount = 'editAccount',
  ChangePassword = 'changePassword',
  MyClients = 'myClients'
}

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements AfterViewInit, OnDestroy {

  public TranslationEnum = TranslationEnum;

  public ClientVm: ClientInfo;
  public adresfv = "";

  public ContractorClientRabatesVm: ContractorClientProfitMargin;
  public Loading:boolean = false;

  public contractor = null;
  public additionalInfo;
  public isAccount = true;
  public isEditAccount = false;
  public changePassword = false;
  public hideOption = false;
  public myClients = false;
  public model: any = {};
  public closeResult: string;
  public isPreview: boolean;
  public modalTittle: string;
  stateButton: KindOper;
  public isClick = false;
  public listContractors: ClientInfo[];
  public clientToDel = 0;
  public addRabateClick = false;
  public CountryList: ICountry[] = [];
  public SelectCountry;
  private subscriptions: Subscription[] = [];
  culture: string;
  ContractorAddresses: any;



  constructor(public formService:FormService, public tresci: TresciService,private _adapter: DateAdapter<any>, private route: ActivatedRoute,private httpClient: HttpClient, private modalService: NgbModal, private _changeDetectorRef: ChangeDetectorRef,
    translationService: TranslateService) {
    this.ClientVm = new ClientInfo();
    this.ContractorClientRabatesVm = new ContractorClientProfitMargin();
    this.getProducts();
    this.getCountryList();
    this.getAdresFV();


    this.culture = window['culture'];
    this._adapter.setLocale(this.culture);

    this.subscriptions.push(translationService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.culture = window['culture'];
      this._adapter.setLocale(this.culture);
      this._changeDetectorRef.detectChanges();
    }));

  }
  getAdresFV() {
    const request = new HttpRequest('POST',   '/api/Contractor/GetInvoiceEmail', {},
    { headers: this.tresci.user.getHeader() });

    this.subscriptions.push(this.tresci.http.request(request)
    .subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          // console.log(event.body);
          this.adresfv = event.body;
      }
    }));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngAfterViewInit() {
    this.getContractor();
    if (this.tresci.user.IsFirst) {
      this.menuUserPanel('changePassword');
    }
    this.formService.getDeliveryAddresses().
    subscribe((data) => {
      this.ContractorAddresses = data.ContractorAddresses;
      
      
    })
  }

  public GetComplain(rok:Number){
    this.Loading = true;
    this.subscriptions.push(this.GetFile(this.culture, rok).subscribe((data) => {

      // this.blob = new Blob([data], {type: 'application/x'});
    
      var downloadURL = window.URL.createObjectURL(data);
      //window.open(downloadURL,'_blank');
      
      var fileLink = document.createElement('a');
      fileLink.href = downloadURL;
      fileLink.target = "_blank"; 
      //fileLink.download = plik.Name;
      fileLink.click();
    }));

  }
  public GetFile(language: string, rok: Number): Observable<any> {
    // var body:PostBody = new PostBody();
     // body.ContractorNumber = ContractorNumber;
     const httpOptions = {
      responseType: 'blob' as 'json'
    };
    this.Loading = false;
     return this.httpClient.get<Blob>('api/Contractor/GetContractorDiscountPrint?culture='+language+'&discountyear='+rok, httpOptions);
 
   }

  onSubmit(userForm) {
    if (userForm.form.valid) {
      this.tresci.user.zmienHaslo(this.model.password);
      this.hideOption = true;
      this.delay(1000).then(any => {
        this.isAccount = true;
        this.isEditAccount = false;
        this.changePassword = false;
        this.hideOption = false;
        this.model.password = '';
        this.model.confirmPassword = '';
      });
    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(resolve, ms));
  }

  public getContractor() {
    const request = new HttpRequest('GET',   '/api/Contractor/GetContractorUser', {},
      { headers: this.tresci.user.getHeader() });

      this.subscriptions.push(this.tresci.http.request(request)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Response:
            this.contractor = event.body;
        }
      }));
  }


  public menuModal(content, kindOper: KindOper, client?) {

    switch (kindOper) {
      case KindOper.PreviewClient:
        this.isPreview = true;
        this.modalTittle = 'szczegoly_klienta ' + client.Name;
        this.fillModal(client);
        this.openModal(content);
      break;

      case KindOper.EditClient:
        this.isPreview = false;
        this.modalTittle = 'edycja_klienta ' + client.Name;
        this.fillModal(client);
        this.openModal(content);
      break;

      case KindOper.AddClient:
        this.isPreview = false;
        this.modalTittle = 'dodaj_klienta';
        this.addClient();
        this.openModal(content);
      break;
    }
  }

  private openModal(content) {
    this.modalService.open(content, { size: 'lg'} ).result.then((result) => {
     this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      if (!this.isPreview) {
        this.getClient();
      }
      this.addRabateClick = false;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


  fillModal(client) {
    this.ClientVm.Address = client.Address;
    this.ClientVm.City = client.City;
    this.ClientVm.Country = client.Country;
    this.ClientVm.Name = client.Name;
    this.ClientVm.NIP = client.NIP;
    this.ClientVm.Id = client.Id;
    this.ClientVm.ContractorNumber = client.ContractorNumber;
    this.ClientVm.ContractorClientProfitMargins = client.ContractorClientProfitMargins;
  }

  addClient() {
    this.ClientVm.Address = null;
    this.ClientVm.City = null;
    this.ClientVm.Country = null;
    this.ClientVm.Name = null;
    this.ClientVm.NIP = null;
    this.ClientVm.Id = null;
    this.ClientVm.ContractorNumber = this.contractor.ContractorNumber;
  }

  addOrUpdate() {
    this.tresci.user.dodajEdytujKlientaKontrahenta(this.ClientVm);
    this.getClientRabates();
  }

   public getClient() {
    this.subscriptions.push(this.tresci.user.pobierzKlientowKontrahenta(this.contractor.ContractorNumber).subscribe( x => this.listContractors = x));
  }

  public getClientRabates() {
    this.subscriptions.push(this.tresci.user.pobierzKlientowKontrahenta(this.contractor.ContractorNumber).subscribe(x => this.listContractors = x));
    const updateClientRebates = this.listContractors.find(x => x.Name === this.ClientVm.Name);
    this.ClientVm.ContractorClientProfitMargins = updateClientRebates.ContractorClientProfitMargins;
  }
  getProducts() {
    this.tresci.GetProducts();
  }

  public checkPosToDel(id) {
    this.clientToDel = id;
    this.isClick = true;
  }

  deleteClient(clientId: any) {
    this.tresci.user.usunKlientaKontrahenta(clientId);
    this.getClient();
  }

  deleteRabate(idRabate: ContractorClientProfitMargin) {
    this.tresci.user.deleteRabate(idRabate.Id);
    const index = this.ClientVm.ContractorClientProfitMargins.indexOf(idRabate);
    this.ClientVm.ContractorClientProfitMargins.splice(index, 1);
  }

  addRabateToList(rabateVm: ContractorClientProfitMargin) {
    rabateVm.ContractorClientId =  this.ClientVm.Id;

    this.ClientVm.ContractorClientProfitMargins.push(rabateVm);
    this.addOrUpdate();

    this.ContractorClientRabatesVm = {
      Id: 0,
      ContractorClientId: this.ClientVm.Id,
      SystemName: '',
      SystemDiscount: 0,
      AccessoryDiscount: 0,
      ElectricityDiscount: 0,
      SystemDisplay: '' };
  }

  async getCountryList() {
    this.CountryList = await this.tresci.user.pobierzKraje();
  }


  public menuUserPanel(kindOper: any) {
    switch (kindOper) {

      case KindOper.EditAccount:
        this.isAccount = true;
        this.isEditAccount = false;
        this.changePassword = false;
        this.myClients = false;
        break;

      case KindOper.ChangePassword:
        this.isAccount = false;
        this.isEditAccount = false;
        this.changePassword = true;
        this.myClients = false;
        break;

      // case KindOper.MyClients:
      //   this.isAccount = false;
      //   this.isEditAccount = false;
      //   this.changePassword = false;
      //   this.myClients = true;
      //   this.getClient();
      //   break;
    }
  }
}
