<div class="container login">
  <div class="row pb-5">
    <div class="col-5">
      <img width="100%"  src="../../assets/images/LogoSeltSVG.svg">
    </div>
    <div class="col-7 ml-auto text-right">
      <mat-form-field class="select mw-100" appearance="outline">
        
        <mat-select [(value)]="Lang" (valueChange)="changeLanguage()" >
          <mat-select-trigger>
            <mat-icon class="flaga flaga{{Lang}}"></mat-icon> <div class="jezyk_login">{{Lang|translate}}</div>
          </mat-select-trigger>
          <mat-option *ngFor="let language of this.translateService.langs" [value]="language">
            <mat-icon class="flaga flaga{{language}}"></mat-icon>{{language|translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="col-md-4 mr-auto ml-auto">
      <mat-form-field class="w-100">
        <span matPrefix>
          <mat-icon class="align-middle mr-2">account_circle</mat-icon>
        </span>
        <input matInput type="text" [(ngModel)]="Username" placeholder="{{'nazwa_uzytkownika'|translate}}" (keyup.enter)="login()">
      </mat-form-field>

      <mat-form-field class="w-100">
        <span matPrefix>
          <mat-icon class="align-middle mr-2">password</mat-icon>
        </span>
        <input matInput type="password" [(ngModel)]="Password" placeholder="{{'haslo'|translate}}" (keyup.enter)="login()">
      </mat-form-field>
      <button mat-flat-button color="primary" class="w-100"
        type="button" (click)='login()'>
        {{'zaloguj'|translate}}
      </button>
      <div class="w-100 mt-2">
        <button mat-button (click)="openContactDialog()">{{'kontakt'|translate}}</button>
        <button mat-button (click)="resetPassword()">{{'resetuj_haslo'|translate}}</button>
        <!-- <button mat-button (click)="routeTo('password/new')" class="float-right">{{'zapomniales_hasla'|translate}}</button> -->
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 ml-auto mr-auto text-center">
      <p>Powered by
        <a href="https://www.selt.com" target="_blank"><b>Selt</b></a>
      </p>
    </div>
  </div>
</div>



