import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { FilterKim } from '../models/form/filter-kim';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { ActiveForm } from '../models/form-model/models/active-form.model';
import { FileControl } from '../models/form/file-control.model';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormControlsService {
  public token: string;

  constructor(private httpClient: HttpClient, private cookieService: CookieService) {
    this.token = this.cookieService.get('_token');
  }

  public getHeaderJson() {
    const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this.token).set('Content-Type', 'application/json');
    /*header.append('Authorization', 'Bearer ' + this.token);*/
    return header;
  }

  public getImgForKim(index: string): Observable<Blob> {
    return this.httpClient.get('api/ActiveForm/GetIndexImage?index=' + index, { responseType: 'blob' });
  }

  public getSystemFilterForKim(): Observable<FilterKim[]> {
    return this.httpClient.get<FilterKim[]>('/api/ActiveForm/SystemFilter?system=' + window['site'] + '&filterNumber=Filter1');
  }

  public getCompanyFilterForKim(): Observable<FilterKim[]> {
    return this.httpClient.get<FilterKim[]>('/api/ActiveForm/SystemFilter?system=' + window['site'] + '&filterNumber=Filter2');
  }

  getApiVisual() {
    // console.log('test')
    return this.httpClient.get<any>('https://api.variantic.com/player/init/en/avrPRpwt9cPmBrSxGJNwWgPPZbBxxmU4');
  }

  public getImg(form: FormGroup, htmlControl: HtmlControl): Observable<Blob> {
    const activeForm: ActiveForm = new ActiveForm();

    activeForm.Parameters.push({ Key: 'id', Value: htmlControl.value });
    activeForm.Parameters.push({ Key: 'jezyk', Value: (window as any)['culture'] });
    Object.keys(form.controls).forEach((key) => {
      const control = form.controls[key].value;
      if (control !== null && control.inputType !== 'newline') {
        if (control.options !== undefined && control.options.length !== 0) {
          activeForm.Parameters.push({ Key: key, Value: control.value.Key });
        } else {
          activeForm.Parameters.push({ Key: key, Value: control.value });
        }
      }
    });

    return this.httpClient.post('/api/ActiveForm/GetImage?typ=' + window['site'] + '&width=1080', activeForm.Parameters, { responseType: 'blob' });
  }

  public getImgForInfo(label: string): Observable<Blob> {
    // console.log("formc1", label);
    return this.httpClient.get('api/StorageFile/Get?id=' + label, { responseType: 'blob' });
  }

  public saveImgForInfo(file: FileControl) {
    const formData: FormData = new FormData();
    formData.append(file.Id, file.File, file.FileName);
    return this.httpClient.post('api/StorageFile/AddOrUpdateFile', formData).pipe(/*take(1)*/).subscribe();
  }

  public removeImgForInfo(id: string) {
    return this.httpClient.post('api/StorageFile/Remove', `{"Id": "${id}"}`, { headers: this.getHeaderJson() }).pipe(/*take(1)*/).subscribe();
  }
}
