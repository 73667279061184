import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormDataV2 } from '../form-model/FormDataV2';
import { GrupaKrokow } from '../form-model/GrupaKrokow';
import { TranslateService } from '@ngx-translate/core';
import { TranslationEnum } from '../../shared/enums/translation-enum';
import { TypEnum } from '../form-model/TypEnum';
import { FormControl, FormGroup } from '@angular/forms';
import { SystemEnum } from 'src/app/shared/enums/system-enum';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { KimControlComponent } from './controls/kim-control/kim-control.component';
import { KimDialogData } from 'src/app/shared/models/form/filter-kim';
import { Kim } from 'src/app/shared/models/form-model/kim.model';
import { FabricControlComponent } from './controls/fabric-control/fabric-control.component';
import { FileControl } from 'src/app/shared/models/form/file-control.model';
import { Parameter } from 'src/app/shared/models/form-model/models/form-controlls.model';
import { PreviewFileDialogComponent } from 'src/app/shared/components/preview-file/preview-file-dialog.component';
import { Values } from '../form-model/Values';
import { Control } from '../form-model/Control';
import { HtmlControl } from 'src/app/shared/models/form-model/models/html-control.model';
import { SafeUrl } from '@angular/platform-browser';
import { FormV2ServiceService } from '../form-v2-service.service';
import { Module } from 'src/app/pomocnicze/module';
import { TresciService } from 'src/app/tresci.service';
import { ViewJSONComponent } from './controls/view-json/view-json.component';
import { SelectMultiColumnControlComponent } from './controls/select-multi-column-control/select-multi-column-control.component';
import { Pergola } from '../form-model/Pergola';
import { EdytujSlupComponent } from './controls/edytuj-slup/edytuj-slup.component';
import { Slup } from '../form-model/Slup';
import * as htmlToImage from 'html-to-image';
import { ParentControlComponent } from './controls/parent-control/parent-control.component';
import { ParentmodControlComponent } from './controls/parent-control/parentmod-control/parentmod-control.component';
import { ParentpozControlComponent } from './controls/parent-control/parentpoz-control/parentpoz-control.component';
import { Wneka } from '../form-model/wneka';


@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss']
})
export class FormControlComponent implements OnInit {

  @Input() control: Control;
  @Input() FormData: FormDataV2;
  @Input() GrupaKrokow: GrupaKrokow;
  @Input() i: number;
  @Input() DevMode: number;
  public module: Module;

  public moduleList:number[] = [];
  public positionList:number[] = [];

  fileInfoId: string = "";
  public kimOpt:Kim[] = [];
  //selectedControl = new FormControl();
  

  public checked:boolean = false;
  
  public TranslationEnum = TranslationEnum;
  system: any;
  public server_url = environment.apiUrl;
  
  pozx = 0;
  pozy = 0;
		
  //private file: FileControl = new FileControl();0000000000000000000000000000000000000000000000000000000000000000000000000
  //EnabledFile: boolean;


  // src: SafeUrl;


  @ViewChild('imgDialog') imgDialog: TemplateRef<any>;


  constructor(private translateService: TranslateService,public dialog: MatDialog, private formService: FormV2ServiceService, public tresci: TresciService) {
    // console.log("site", localStorage.site, window['site']);
   if(localStorage.site == 'stale'){
    this.system = SystemEnum[window['site']];
   } else {
    this.system = SystemEnum[localStorage.site];
   }
    
  }
  form:FormGroup;


  onDragEnd(event, slup:Slup, id){
    
    slup.pozx += Math.round( event.distance.x /slup.skala / 10) *10;
    slup.pozy += Math.round(event.distance.y /slup.skala / 10) *10;
    // console.log(slup.pozx, slup.pozy, event, slup.skala);
    slup.ustaw(this.control.pergola, id);
    slup.jakaStrona(this.control.pergola);
    slup.ustaw_wym();
    this.control.pergola.JakiWymiar();
    slup.przenosze = false;
   
    this.zmienWartosc(this.control.pergola.getValue(), false);
  }
  onDragStart(event, slup:Slup, id){
    if(id > 3){
      slup.przenosze = true;
    }
  }
  skalaMinus(){
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      this.control.pergola.ustawSkaleMinus();
      this.control.pergola.slupy.forEach(s => {
        s.ustaw_wym();
      });
      this.control.pergola.JakiWymiar();
    }
    
  }
  skalaPlus(){
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      this.control.pergola.ustawSkalePlus();
      this.control.pergola.slupy.forEach(s => {
        s.ustaw_wym();
      });
      this.control.pergola.JakiWymiar();
    }
    
  }
 
  GetImg() {

    this.formService.getImg(this.FormData, this.control).pipe(/*take(1)*/).subscribe(data => {
      this.createImageFromBlob(data);
      //const dialogRef = this.dialog.open(this.imgDialog,  { minWidth: '1080px'});
      // dialogRef.afterClosed().subscribe((result) => {
      //    // console.log(`Dialog result: ${result}`);
      // });
    }, error => {
      this.control.src = '';
    });
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.control.src = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  otworzSlup(numer){
    
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      if(numer == 0 && (this.control.pergola.blokadaA || this.control.pergola.blokadaD)){
        this.errorBlokada();
      } else if(numer == 1 && (this.control.pergola.blokadaA || this.control.pergola.blokadaB)){
        this.errorBlokada();
      } else if(numer == 2 && (this.control.pergola.blokadaC || this.control.pergola.blokadaD)){
        this.errorBlokada();
      } else if(numer == 3 && (this.control.pergola.blokadaB || this.control.pergola.blokadaC)){
        this.errorBlokada();
      } else {
        this.control.pergola.Edytuj(numer);

        var dialogRef = this.dialog.open(EdytujSlupComponent, {
          maxWidth: Math.round(window.innerWidth * 0.6569),
          // maxHeight: Math.round(window.innerHeight * 0.9595),
          width: '100%',
          // height: '100%',
          disableClose: true,
          data: this.control.pergola,
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.zmienWartosc(this.control.pergola.getValue(), false);
          this.control.pergola.slupy.forEach(s => {
            s.sprawdzwys(true);
          });
        });
      }
     
    }
  }
  errorBlokada(){
    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
    const data: DialogData = {
      title: 'slup_zablokowany',
      content: 'slup_zablokowany_content',
      answerOk: true,
    };
    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
  }

  ngOnInit(): void {
    this.fileInfoId = 'plik_info_' + this.control.KeyName.toLocaleLowerCase() + "_" + localStorage.site + "_" + this.FormData.culture;
    // console.log("control: ", this.control);


    if(this.control.ControlType === "WyborParent"){
      if(this.control.wybrany != null && (this.control.wybrany.DisplayValue == null || this.control.wybrany.DisplayValue == '')){
        this.control.wybrany.DisplayValue = this.control.wybrany.Value;
      }
    }
    if(this.control.ControlType === "WyborParentModule"){
      if(this.control.wybrany != null && (this.control.wybrany.DisplayValue == null || this.control.wybrany.DisplayValue == '')){
        this.control.wybrany.DisplayValue = this.control.wybrany.Value;
      }
      this.FormData.parent_Parameters.forEach(param => {
        if(param.Module != 0){
          var y = this.moduleList.find(x=> param.Module == x);
          if(y == null){
            this.moduleList.push(param.Module);
          }
        }
      });
      if(this.moduleList.length == 1){
        this.zmienWartosc(this.moduleList[0], false);
      }
      // console.log("par mod", this.moduleList);
    }
    if(this.control.ControlType === "WyborParentPosition"){
      if(this.control.wybrany != null && (this.control.wybrany.DisplayValue == null || this.control.wybrany.DisplayValue == '')){
        this.control.wybrany.DisplayValue = this.control.wybrany.Value;
      }
      this.FormData.parent_Parameters.forEach(param => {
        if(param.Module == this.FormData.supervisor.getAktualnyMod()){
          var y = this.positionList.find(x=> param.Position == x);
          if(y == null && param.Position != 0){
            this.positionList.push(param.Position);
          }
        }
      });
      if(this.positionList.length == 1){
        this.zmienWartosc(this.positionList[0], false);
      }
      // console.log("par pos", this.positionList);
    }
    if(this.control.ControlType === "Check"){
      if(this.control.wybrany.Value === 'TAK'){
        this.checked = true;
      } else {
        this.checked = false;
      }
    }

    if(this.control.ControlType === "Render"){
      this.GetImg();
     
    }

    
    if(this.control.ControlType === "Select" || this.control.ControlType === "SelectImg"){
      this.form = new FormGroup({
        state: new FormControl({value: this.control.wybrany.Value, disabled: this.GrupaKrokow.CzyAktywny(this.i)}),
      });
    }
    if(this.control.ControlType === "CheckNumber"){
      // console.log("wb", this.control.wybrany.Value);
      if(this.control.wybrany.Value == null || this.control.wybrany.Value === ''){
        this.checked = false;
      } else {
        this.checked = true;
      }
    }
    if(this.control.ControlType === "CheckSelect"){
      if(this.control.wybrany.Value == null || this.control.wybrany.Value == ''){
        this.checked = false;
      } else {
        this.checked = true;
      }
      this.form = new FormGroup({
        state: new FormControl({value: this.control.wybrany.Value, disabled: this.GrupaKrokow.CzyAktywny(this.i) && this.checked}),
      });
    }
    if(this.control.ControlType === "CheckSelectMultiColumn"){
      if(this.control.wybrany.Value == null || this.control.wybrany.Value == ''){
        this.checked = false;
      } else {
        this.checked = true;
      }
      this.form = new FormGroup({
        state: new FormControl({value: this.control.wybrany.Value, disabled: this.GrupaKrokow.CzyAktywny(this.i) && this.checked}),
      });
    }
    
    if(this.control.ControlType === "Kim"){
      this.zmienWartoscDlaKim(false);
    }
    if(this.control.ControlType === "File"){
      this.checked = true;
    }


    if(this.control.ControlType === "CheckFile" || this.control.ControlType === "File"){
      // console.log("file", this.control.wybrany);

     

      if(this.control.Values.length > 0 || this.control.Values[0].File != null){
       
       /*
        this.control.wybrany.DisplayValue = this.control.Values[0].DisplayValue;
        this.control.wybrany.DisplayValueCs = this.control.Values[0].DisplayValueCs;
        this.control.wybrany.DisplayValueDe = this.control.Values[0].DisplayValueDe;
        this.control.wybrany.DisplayValueEn = this.control.Values[0].DisplayValueEn;
        this.control.wybrany.DisplayValueFr = this.control.Values[0].DisplayValueFr;
        this.control.wybrany.DisplayValueIt = this.control.Values[0].DisplayValueIt;
        this.control.wybrany.DisplayValuePl = this.control.Values[0].DisplayValuePl;
        this.control.wybrany.Value = this.control.Values[0].Value;
        this.control.wybrany.Key = this.control.KeyName;
        this.control.wybrany.File = new FileControl();
        this.control.wybrany.File.Id = this.control.Values[0].Key;
        this.control.wybrany.File.Key = this.control.Values[0].Key;


            const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
              const byteCharacters = atob(b64Data);
              const byteArrays = [];
    
              for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
    
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }
    
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }
    
              const blob = new Blob(byteArrays, { type: contentType });
              return blob;
            };
            this.control.wybrany.File.File = b64toBlob(this.control.Values[0].File);
            this.control.wybrany.File.FileName = this.control.Values[0].FileName;
            */
            //this.formService.Files.push(this.file);
            //this.Group.get(this.Control.name).setValue(this.Control);
          
        
    

      }


    }
    if(this.control.ControlType == "CheckFile"){
      if(this.control.wybrany.Value == null || this.control.wybrany.Value === ''){
        this.checked = false;
        this.usunPlik();
      } else {
        this.checked = true;
      }
    }
    if(this.control.ControlType == "Module"){
      this.module = new Module(this.control.KeyName, this.control.DefaultValue, this.control.KeyName, this.control.DefaultValue, this.tresci, '', 1);
      // console.log("Module", this.control);

      if(this.control.wybrany.Value != null){
        var moduly =  this.control.wybrany.Value.split(",");
        var i = 0;
        moduly.forEach(element => {
          var el = element.split(":");
          this.module.set(el[1], el[0]);
          i++;
        });
        while(i > this.FormData.moduly[this.FormData.supervisor.aktualny_mod - 1].pozycje.length){
            this.FormData.DodajPozycjeAutoBez( this.FormData.supervisor.aktualny_mod - 1);
        }
      }
        
    } 


    
  }
  zmienionoParametr(){
    
    this.FormData.ZmianaParametru(this.control, this.formService);
  }
  controlJSON(){
    return JSON.stringify(this.control, null, 2);
  }

  generateImage(){
    this.control.pergola.setScaleStd();
    this.control.pergola.slupy.forEach(s => {
      s.ustaw_wym();
    });
    this.control.pergola.JakiWymiar();
var img = "";
    var node:any = document.getElementById('Pergola'+this.control.pergola.random);
    htmlToImage.toPng(node)
      .then(function (dataUrl) {
        //this.img = new Image();
        // console.log("img", dataUrl)
        var link = document.createElement('a');
        link.download = 'screen.png';
        link.href = dataUrl;
        // console.log("dat",dataUrl);
        img = dataUrl;
        link.click();
        // this.src = dataUrl;

       //  document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      }).finally(() => {
        // console.log("test ttt", img);
        
        const contentType = 'image/png';

        var f = img.split("base64,");
      const byteCharacters = atob(f[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {type: contentType});

        if(this.control.wybrany.File == null){
          this.control.wybrany.File = new FileControl();
        }

       // if (this.FormData.Files.find((x: FileControl) => x.Key === this.control.wybrany.File.Key)) {
        //  this.FormData.Files.splice(this.FormData.Files.indexOf(this.control.wybrany.File), 1);
        //}
        /*
        this.control.wybrany.File.File = blob;
        this.control.wybrany.FileName = "Pergola_"+this.control.KeyName;
        this.control.wybrany.File.FileName = "Pergola_"+this.control.KeyName+".png";
        this.FormData.Files.push(this.control.wybrany.File);
        

          this.FormData.Files.push(this.control.wybrany.File);*/

      }
      );
      
    
  }



  usunPlik(){
    // console.log("usuwam plik");
    //if (this.FormData.Files.find((x: FileControl) => x.Id === this.control.wybrany.File.Id)) {
      // console.log(this.control.wybrany);

    if (this.control.wybrany.File != null && this.FormData.Files.find((x: FileControl) => x.Key === this.control.wybrany.File.Key)) {
      this.FormData.Files.splice(this.FormData.Files.indexOf(this.control.wybrany.File), 1);
    }
    this.control.wybrany = new Parameter();

  }
  changeImg(e?: any): void {
    // console.log("zmieniam plik");
    if(this.control.wybrany.File == null){
      this.control.wybrany.File = new FileControl();
    }
    //  if (this.FormData.Files.find((x: FileControl) => x.Id === this.control.wybrany.File.Id)) {
    if (this.FormData.Files.find((x: FileControl) => x.Key === this.control.wybrany.File.Key)) {
      this.FormData.Files.splice(this.FormData.Files.indexOf(this.control.wybrany.File), 1);
    }

    const addFile = e.target.files.item(0);
    this.control.wybrany.File.File = addFile;
    this.control.wybrany.FileName = addFile.name;
    this.control.wybrany.File.FileName = addFile.name;
    this.FormData.Files.push(this.control.wybrany.File);
    if(this.control.Values.length > 0 || this.control.Values[0].File != null){
       
      // console.log("file contr", this.control.Values[0]);
       this.control.wybrany.DisplayValue = this.control.Values[0].DisplayValue;
       this.control.wybrany.DisplayValueCs = this.control.Values[0].DisplayValueCs;
       this.control.wybrany.DisplayValueDe = this.control.Values[0].DisplayValueDe;
       this.control.wybrany.DisplayValueEn = this.control.Values[0].DisplayValueEn;
       this.control.wybrany.DisplayValueFr = this.control.Values[0].DisplayValueFr;
       this.control.wybrany.DisplayValueIt = this.control.Values[0].DisplayValueIt;
       this.control.wybrany.DisplayValuePl = this.control.Values[0].DisplayValuePl;
       this.control.wybrany.Value = this.control.Values[0].Key;
       this.control.wybrany.Key = this.control.KeyName;
       this.control.wybrany.Module = this.control.Values[0].Module.valueOf();
       this.control.wybrany.Position = this.control.Values[0].Position.valueOf();
       this.control.wybrany.Type = this.control.Values[0].Type;
       this.control.wybrany.File.Id = this.control.Values[0].Key;
       this.control.wybrany.File.Key = this.control.KeyName;


           
         
       
   

     }
     // console.log("file2", this.control);
  }

  fileExist() {
    
    if(this.control.wybrany.File == null || this.control.wybrany.File.File == null || this.control.wybrany.File.File === ''){
      // console.log("w", this.control);
      return false;
    } 
    return true;
  }
  previewFile() {
     // console.log("control", this.FormData.Files, this.control.wybrany);
    if (this.control.wybrany.File.File.type!= null && (this.control.wybrany.File.File.type.includes('png') || this.control.wybrany.File.File.type.includes('jpeg'))) {
      const dialogRef = this.dialog.open(PreviewFileDialogComponent, { data: this.control.wybrany.File.File });

      dialogRef.afterClosed().pipe(/*take(1)*/).subscribe((result) => {
      });
    }  else {
      // console.log("file", this.control.wybrany.File.File);
      try {
        //window.open(URL.createObjectURL(this.control.wybrany.File.File), '_blank');
        const a: any = document.createElement('a');
        a.download = this.control.wybrany.FileName; // or whatever your file name is
        a.href = URL.createObjectURL(this.control.wybrany.File.File);
        a.click();
      } catch(Exception){
        
      }
  

    }
  }

  getMinNumber(){
    var min = 0;
    this.control.Parameters.forEach(param => {
      if(param.Key == "Min"){
        min = Number.parseInt(param.Value);
      }
    });
    return min;
  }
  getMaxNumber(){
    var max = 0;
    this.control.Parameters.forEach(param => {
      if(param.Key == "Max"){
        max = Number.parseInt(param.Value);
      }
    });
    return max;
  }
  changeNumber(control:Control){
    var val = Number.parseInt(control.wybrany.Value);
    // console.log("isNaN no ", control, val);
   /*  if(isNaN(val)){
      control.wybrany.Value = this.getMinNumber();
    }else {
      if(val < this.getMinNumber()){
        control.wybrany.Value = this.getMinNumber();
      } else if(val > this.getMaxNumber()){
        control.wybrany.Value = this.getMaxNumber();
      }
    }
    */
    if(!isNaN(val)){
      control.wybrany.Value = control.wybrany.Value * 1;
      control.wybrany.DisplayValue = control.wybrany.Value;
      control.wybrany.DisplayValueCs = control.wybrany.Value;
      control.wybrany.DisplayValueDe = control.wybrany.Value;
      control.wybrany.DisplayValueEn = control.wybrany.Value;
      control.wybrany.DisplayValueFr = control.wybrany.Value;

      control.wybrany.DisplayValueIt = control.wybrany.Value;
      control.wybrany.DisplayValuePl = control.wybrany.Value;
    }
     // console.log("control change", control, this.control);
        
    this.FormData.ZmianaParametru(control, this.formService);
  }
  changeText(control:Control){
    var blad_znakow = false;
    /*if(control.wybrany.Value.includes('-')){
      control.wybrany.Value = control.wybrany.Value.replaceAll('-', '');
      blad_znakow = true;
    }
    if(control.wybrany.Value.includes('+')){
      control.wybrany.Value = control.wybrany.Value.replaceAll('+', '');
      blad_znakow = true;
    }*/
    if(control.wybrany.Value.includes('&')){
      control.wybrany.Value = control.wybrany.Value.replaceAll('&', '');
      blad_znakow = true;
    }
    /*if(control.wybrany.Value.includes('*')){
      control.wybrany.Value = control.wybrany.Value.replaceAll('*', '');
      blad_znakow = true;
    }
    if(control.wybrany.Value.includes('/')){
      control.wybrany.Value = control.wybrany.Value.replaceAll('/', '');
      blad_znakow = true;
    }*/
    if(control.wybrany.Value.includes('<')){
      control.wybrany.Value = control.wybrany.Value.replaceAll('<', '');
      blad_znakow = true;
    }
    if(control.wybrany.Value.includes('>')){
      control.wybrany.Value = control.wybrany.Value.replaceAll('>', '');
      blad_znakow = true;
    }

    if(blad_znakow){
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: '',
        content: 'uzyto_niedozwolone_znaki',
        answerOk: true,
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    }

 
    // console.log("control change 1", control, this.control);
    this.FormData.ZmianaParametru(control, this.formService);
  }

  czyAktywnySelect() {
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      this.form.enable();
    } else {
      this.form.disable();
    }
    
    return this.GrupaKrokow.CzyAktywny(this.i);
  }
  czyAktywnyCheckSelect() {
    if(this.GrupaKrokow.CzyAktywny(this.i) && this.checked){
      this.form.enable();
    } else {
      this.form.disable();
    }
    
    return this.GrupaKrokow.CzyAktywny(this.i) && this.checked;
  }
  changeSelect(event:any){
    // console.log("select: ", event, this.control.KeyName );
    if(this.control.KeyName == 'Moduł' && event.value == 'X'){

      this.form.controls['state'].setValue({value: ''});
      this.control.wybrany.Value = null;
      this.zmienWartosc('', false);

      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const dataDial: DialogData = {
      title: '',
      content: 'pole_moduł_jest_x',
      answerOk: true,
      };
      dialogRef =  this.dialog.open(UniversalDialogComponent, { data: dataDial, role: 'alertdialog' });
      
    } else {
      this.zmienWartosc(event.value, false);
     
    }
    
  }
  changeCheck(event:any){
    if(this.checked){
      this.zmienWartosc('TAK', false);
    } else {
      this.zmienWartosc('NIE', false);
    }

  }
  changeCheckWneka(event:any, i){
    if(this.control.pergola.Wneki[i].checked){
      this.control.pergola.Wneki_wybrane.push(this.control.pergola.Wneki[i]);
    } else {  
      var ind = this.control.pergola.Wneki_wybrane.findIndex(x => x.pozx == this.control.pergola.Wneki[i].pozx && x.pozy == this.control.pergola.Wneki[i].pozy && x.sciana == this.control.pergola.Wneki[i].sciana);
      if(ind != null){
         this.control.pergola.Wneki_wybrane.splice(ind, 1);
      }
      console.log("wneki ind: ", ind );
    }
    this.control.pergola.JakiWymiar();
    console.log("wneki: ", this.control.pergola.Wneki_wybrane );
  }
  getNumerWneki(i){
    
  }
  changeCheckFile(event:any){
    // console.log(this.control);
    if(this.checked){
      this.control.wybrany.Key = "CheckX";
      //this.zmienWartosc(0, false);
    } else {
      this.control.wybrany = new Parameter();
      
    }
    
  }
  changeCheckNumber(event:any){
    // console.log(this.control);
    if(this.checked){
      this.zmienWartosc(0, false);
    } else {
      this.control.wybrany.Value = null;
    }
  
    
  }
  CzyLiczba(val){
    var w:Number = Number.parseInt(val);
    if(isNaN(w.valueOf())){
      return false;
    } else {
      return true;
    }
    return false;
  }

  changeCheckSelectMulti(event:any){
    // console.log("checksel", this.control, event, this.checked);
    this.czyAktywnyCheckSelect();
    if(this.checked){
      this.zmienWartosc('----', false);
    } else {
      this.zmienWartosc('', false);
      this.form.controls['state'].setValue({value: ''});
      this.control.wybrany.Value = null;
      this.control.wybrany.Kolor = null;
    }
   
  }
  changeCheckSelect(event:any){
    // console.log("checksel", this.control, event, this.checked);
    this.czyAktywnyCheckSelect();
    if(this.checked){
      this.zmienWartosc('----', false);
    } else {
      this.zmienWartosc('', false);
      this.form.controls['state'].setValue({value: ''});
      this.control.wybrany.Value = null;
    }
    
  }


  zmienWartoscDlaKim(czyZmien = true){
    this.utworzKimOpt();
    var wartosc: string = "";
    
    // console.log("V2", this.kimOpt);
    this.kimOpt.forEach(par => {
      
      if(par.Quantity > 0){
        wartosc += par.Name + " x" + par.Quantity + ", ";
      }
    });
   
    
    // console.log("V3", wartosc);
    this.zmienWartoscZ(wartosc, false, czyZmien); 
  }
  utworzKimOpt(){
    
    var max = Number.parseInt(this.control.Parameters.find((x) => x.Key === 'Max').Value);
    var blad = '';
    var blad_brak = '';


    var mod_tmp:Number = 0;
    var poz_tmp:Number = 0;

    this.kimOpt = [];
    // console.log("P", this.FormData);
    
    if(this.FormData.ParameterExtensions == null){
      this.FormData.ParameterExtensions = [];
    }
    // console.log("P", this.FormData);
    this.FormData.ParameterExtensions.forEach(p => {
      p.jest = false;

    });

    this.control.Values.forEach(v => {
      var k:Kim = new Kim(v);
      mod_tmp = v.Module;
      poz_tmp = v.Position;

      // console.log("PPP", mod_tmp, poz_tmp);
      
      this.FormData.ParameterExtensions.forEach(p => {

        if(p.Index == k.Index && p.Position == k.Position && p.Module == k.Module && p.IsActive){
          if(p.Quantity > max){
            p.Quantity = max;
            k.Quantity = max;
            if(k.Name == null){
              k.Name = '';
            }
            if(k.CategoryCode == null){
              k.CategoryCode = '';
            }
            blad+= k.Name.trim() +"("+k.Index.trim()+") "+k.CategoryCode.trim()+", ";
          } else {
            k.Quantity = p.Quantity; 
          }
          p.jest = true;
        } 

      });
      this.kimOpt.push(k);
    });
    // console.log("mod poz", mod_tmp, poz_tmp);
    for(var i =  this.FormData.ParameterExtensions.length - 1; i >= 0 ;i--){
      if(!this.FormData.ParameterExtensions[i].jest && this.FormData.ParameterExtensions[i].Module == mod_tmp && this.FormData.ParameterExtensions[i].Position == poz_tmp){
        if(this.FormData.ParameterExtensions[i].CategoryCode == null){
          this.FormData.ParameterExtensions[i].CategoryCode = '';
        }
        blad_brak+= this.FormData.ParameterExtensions[i].Index.trim()+" ("+this.FormData.ParameterExtensions[i].CategoryCode.trim()+"), ";
        this.FormData.ParameterExtensions.splice(i,1);
      }
    }
    
    if(blad != '') {
     // this.zmienWartoscDlaKim(true);
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: 'zmniejszono_ilosc',
        content: blad,
        answerOk: true,
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    }
    if(blad_brak != '') {
     // this.zmienWartoscDlaKim(true);
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: 'wycofano_akcesoria',
        content: blad_brak,
        answerOk: true,
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    }


  }
  otworzParent(){
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      var dialogRef = this.dialog.open(ParentControlComponent, {
        maxWidth: Math.round(window.innerWidth * 0.6569),
        // maxHeight: Math.round(window.innerHeight * 0.9595),
        width: '100%',
        // height: '100%',
        disableClose: true,
        data: this.control,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.zmienWartosc(this.control.wybrany.Value, false);
        this.FormData.parent_Parameters = this.control.ParentParameters;
        // console.log("wyb: ", this.FormData.parent_Parameters);
      });
    }
  }
  otworzParentMod(){
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      var dialogRef = this.dialog.open(ParentmodControlComponent, {
        maxWidth: Math.round(window.innerWidth * 0.6569),
        // maxHeight: Math.round(window.innerHeight * 0.9595),
        width: '100%',
        // height: '100%',
        disableClose: true,
        data: {'control':this.control, 'formData': this.FormData, 'list': this.moduleList},
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.zmienWartosc(this.control.wybrany.Value, false);
        //this.FormData.parent_Parameters = this.control.ParentParameters;
        // console.log("wyb: ", this.FormData.parent_Parameters);
      });
    }
  }
  otworzParentPoz(){
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      var dialogRef = this.dialog.open(ParentpozControlComponent, {
        maxWidth: Math.round(window.innerWidth * 0.6569),
        // maxHeight: Math.round(window.innerHeight * 0.9595),
        width: '100%',
        // height: '100%',
        disableClose: true,
        data: {'control':this.control, 'formData': this.FormData, 'list': this.positionList},
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.zmienWartosc(this.control.wybrany.Value, false);
       // this.FormData.parent_Parameters = this.control.ParentParameters;
        // console.log("wyb: ", this.FormData.parent_Parameters);
      });
    }
  }
  otworzFabric(){
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      var dialogRef = this.dialog.open(FabricControlComponent, {
        maxWidth: Math.round(window.innerWidth * 0.6569),
        // maxHeight: Math.round(window.innerHeight * 0.9595),
        width: '100%',
        // height: '100%',
        disableClose: true,
        data: this.control,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.zmienWartosc(this.control.wybrany.Value, false);
      
      });
    }
  }
  otworzSelectMultiColumnCheck(){
    if(this.checked && this.GrupaKrokow.CzyAktywny(this.i)){
      if(this.GrupaKrokow.CzyAktywny(this.i)){
        var dialogRef = this.dialog.open(SelectMultiColumnControlComponent, {
          maxWidth: Math.round(window.innerWidth * 0.6569),
          // maxHeight: Math.round(window.innerHeight * 0.9595),
          width: '100%',
          // height: '100%',
          disableClose: true,
          data: this.control,
        });
        dialogRef.afterClosed().subscribe((result) => {
          // console.log("wyb: ", this.control);
          this.zmienWartosc(this.control.wybrany.Value, false);
          
        });
      }
    }
    
  }

  otworzSelectMultiColumn(){
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      var dialogRef = this.dialog.open(SelectMultiColumnControlComponent, {
        maxWidth: Math.round(window.innerWidth * 0.6569),
        // maxHeight: Math.round(window.innerHeight * 0.9595),
        width: '100%',
        // height: '100%',
        disableClose: true,
        data: this.control,
      });
      dialogRef.afterClosed().subscribe((result) => {
        // console.log("wyb: ", this.control);
        this.zmienWartosc(this.control.wybrany.Value, false);
        
      });
    }
  }

  otworzDev(){
    var dialogRef = this.dialog.open(ViewJSONComponent, {
      maxWidth: Math.round(window.innerWidth * 0.6569),
      // maxHeight: Math.round(window.innerHeight * 0.9595),
      width: '100%',
      // height: '100%',
      disableClose: true,
      data: this.control,
    });
  }

  otworzKim(){
    if(this.GrupaKrokow.CzyAktywny(this.i)){
  
      this.utworzKimOpt();
      const kimDialogData: KimDialogData = {
        Data: this.kimOpt,
        IsPossition: false,
        Max: Number.parseInt(this.control.Parameters.find((x) => x.Key === 'Max').Value),
      };
      // console.log(kimDialogData);
      var dialogRef = this.dialog.open(KimControlComponent, {
        maxWidth: Math.round(window.innerWidth * 0.6569),
        // maxHeight: Math.round(window.innerHeight * 0.9595),
        width: '100%',
        // height: '100%',
        disableClose: false,
        data: kimDialogData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        // console.log("r", this.kimOpt);
        var mod = 0;
        var poz = 0;
        var type = 0;
        if(this.kimOpt.length > 0){
          mod = this.kimOpt[0].Module;
          poz = this.kimOpt[0].Position;
          type = this.kimOpt[0].Type;
        }
        

        for(var i = this.FormData.ParameterExtensions.length - 1;i >= 0;i--){
            if(this.FormData.ParameterExtensions[i].Module == mod && this.FormData.ParameterExtensions[i].Position == poz){ //} && this.FormData.ParameterExtensions[i].Type == type){
              this.FormData.ParameterExtensions.splice(i,1);
            }
        }

        this.kimOpt.forEach(kim => {
          if(kim.Quantity > 0){
            this.FormData.ParameterExtensions.push(kim);
          }
        });
        this.zmienWartoscDlaKim();
        // console.log("r2", this.FormData.ParameterExtensions);
      });
    }
  }
  zmienWartoscAdmin(wartosc, disabled:boolean){
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      var byl = false;
     

      if(!disabled){
        if(this.control.Values != null){
          this.control.Values.forEach(value => {
            if(value.Key == wartosc){
              byl= true;
              this.control.wybrany.Value = value.Key;
              this.control.wybrany.DisplayValue = value.DisplayValue;
              this.control.wybrany.DisplayValueCs = value.DisplayValueCs;
              this.control.wybrany.DisplayValueDe = value.DisplayValueDe;
              this.control.wybrany.DisplayValueEn = value.DisplayValueEn;
              this.control.wybrany.DisplayValueFr = value.DisplayValueFr;
              this.control.wybrany.DisplayValueIt = value.DisplayValueIt;
              this.control.wybrany.DisplayValuePl = value.DisplayValuePl;
              this.control.wybrany.Type = value.Type;
              if(this.control.ControlType === 'Select' || this.control.ControlType === 'SelectImg' ){
                // console.log("control select " + wartosc + " value",  value, this.form.controls['state'].value);
                this.form.controls['state'].patchValue(value.Key);
                //this.form.controls['state'].setValue({value:  value.Key});
                // console.log("control select " + wartosc + " value",  value, this.form.controls['state'].value);
              }
            }
            // 
          });
        }
        
        if(!byl){
          this.control.wybrany.Value = wartosc;
          this.control.wybrany.DisplayValue = wartosc;
          this.control.wybrany.DisplayValueCs = wartosc;
          this.control.wybrany.DisplayValueDe = wartosc;
          this.control.wybrany.DisplayValueEn = wartosc;
          this.control.wybrany.DisplayValueFr = wartosc;
          this.control.wybrany.DisplayValueIt = wartosc;
          this.control.wybrany.DisplayValuePl = wartosc;
          this.control.wybrany.Type = this.TypeToNumber(this.control.ControlType);
          if(this.control.ControlType === 'Select' || this.control.ControlType === 'SelectImg' ){
            this.form.controls['state'].patchValue(wartosc);
          }
          // console.log("control " + wartosc, this.control);
        } else {
          // console.log("control2 " + wartosc, this.control);
        }
       
        // console.log("control change 2", this.control);
        this.FormData.ZmianaParametru(this.control, this.formService);
      }
    }
  }
  zmienWartoscZ(wartosc, disabled:boolean, czyZmien:boolean = true){
    // console.log("control change 4", this.control);
    
      var byl = false;
      
        if(this.control.Values != null){
          this.control.Values.forEach(value => {
            if(value.Key == wartosc){
              byl= true;
              this.control.wybrany.Value = value.Key;
              this.control.wybrany.DisplayValue = value.DisplayValue;
              this.control.wybrany.DisplayValueCs = value.DisplayValueCs;
              this.control.wybrany.DisplayValueDe = value.DisplayValueDe;
              this.control.wybrany.DisplayValueEn = value.DisplayValueEn;
              this.control.wybrany.DisplayValueFr = value.DisplayValueFr;
              this.control.wybrany.DisplayValueIt = value.DisplayValueIt;
              this.control.wybrany.DisplayValuePl = value.DisplayValuePl;
              this.control.wybrany.Type = value.Type;
            }
            // console.log("control " + wartosc + " value",  value);
          });
        }
        
        if(!byl){
          this.control.wybrany.Value = wartosc;
          this.control.wybrany.DisplayValue = wartosc;
          this.control.wybrany.DisplayValueCs = wartosc;
          this.control.wybrany.DisplayValueDe = wartosc;
          this.control.wybrany.DisplayValueEn = wartosc;
          this.control.wybrany.DisplayValueFr = wartosc;
          this.control.wybrany.DisplayValueIt = wartosc;
          this.control.wybrany.DisplayValuePl = wartosc;
          this.control.wybrany.Type = this.TypeToNumber(this.control.ControlType);
          // console.log("control " + wartosc, this.control);
        } else {
          // console.log("control2 " + wartosc, this.control);
        }
        // console.log("control change 3", this.control);
      
        if(czyZmien){
          this.FormData.ZmianaParametru(this.control, this.formService);
        }
        
      
    
  }
  zmienWartosc(wartosc, disabled:boolean, czyZmien:boolean = true){
    // console.log("control change 4", this.control);
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      var byl = false;
      if(!disabled){
        if(this.control.Values != null){
          this.control.Values.forEach(value => {
            if(value.Key == wartosc){
              byl= true;
              this.control.wybrany.Value = value.Key;
              this.control.wybrany.DisplayValue = value.DisplayValue;
              this.control.wybrany.DisplayValueCs = value.DisplayValueCs;
              this.control.wybrany.DisplayValueDe = value.DisplayValueDe;
              this.control.wybrany.DisplayValueEn = value.DisplayValueEn;
              this.control.wybrany.DisplayValueFr = value.DisplayValueFr;
              this.control.wybrany.DisplayValueIt = value.DisplayValueIt;
              this.control.wybrany.DisplayValuePl = value.DisplayValuePl;
              this.control.wybrany.Type = value.Type;
            }
            // console.log("control " + wartosc + " value",  value);
          });
        }
        
        
        if(!byl){
          this.control.wybrany.Value = wartosc;
          this.control.wybrany.DisplayValue = wartosc;
          this.control.wybrany.DisplayValueCs = wartosc;
          this.control.wybrany.DisplayValueDe = wartosc;
          this.control.wybrany.DisplayValueEn = wartosc;
          this.control.wybrany.DisplayValueFr = wartosc;
          this.control.wybrany.DisplayValueIt = wartosc;
          this.control.wybrany.DisplayValuePl = wartosc;
          this.control.wybrany.Type = this.TypeToNumber(this.control.ControlType);
          // console.log("control " + wartosc, this.control);
        } else {
          // console.log("control2 " + wartosc, this.control);
        }
        // console.log("control change 3", this.control);

        

        if(czyZmien){
          this.FormData.ZmianaParametru(this.control, this.formService);
        }
        
      }
    }
    // console.log("con", wartosc, this.control.wybrany);
  }
  zmienWartoscDisplay(wartosc, wartoscdisplay, disabled:boolean, czyZmien:boolean = true){
    // console.log("control change 4", this.control);
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      var byl = false;
      if(!disabled){
       
        
        
        if(!byl){
          this.control.wybrany.Value = wartosc;
          this.control.wybrany.DisplayValue = wartoscdisplay;
          this.control.wybrany.DisplayValueCs = wartoscdisplay;
          this.control.wybrany.DisplayValueDe = wartoscdisplay;
          this.control.wybrany.DisplayValueEn = wartoscdisplay;
          this.control.wybrany.DisplayValueFr = wartoscdisplay;
          this.control.wybrany.DisplayValueIt = wartoscdisplay;
          this.control.wybrany.DisplayValuePl = wartoscdisplay;
          this.control.wybrany.Type = this.TypeToNumber(this.control.ControlType);
          // console.log("control " + wartosc, this.control);
        } else {
          // console.log("control2 " + wartosc, this.control);
        }
        // console.log("control change 3", this.control);

        

        if(czyZmien){
          this.FormData.ZmianaParametru(this.control, this.formService);
        }
        
      }
    }
    // console.log("con", wartosc, this.control.wybrany);
  }
  SetModule(x,y){
    // console.log(y,x, this.module);
    var IsMod = this.module.module_array[x][y];
    var number = this.module.getName(x,y);
    if(!this.module.set(x,y)){
      if(IsMod){
        let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
        const data: DialogData = {
          title: '',
          content: 'nie_mozna_usunac',
          answerOk: true,
        };
        dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      } else {
        let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
        const data: DialogData = {
          title: '',
          content: 'nie_mozna_dodac',
          answerOk: true,
        };
        dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      }
      
    } else {
      if(!IsMod){
        this.FormData.DodajPozycjeAuto( this.FormData.supervisor.aktualny_mod - 1, this.module.getName(x,y));
      } else {
        this.FormData.UsunPozycjeAuto(this.tresci, this.formService, false,  this.FormData.supervisor.aktualny_mod - 1, number - 1, null);
        // this.FormData.UsunPozycje(this.tresci, this.formService, false,  this.FormData.supervisor.aktualny_mod - 1, number - 1, null);
      }
      
      this.zmienWartoscDisplay(this.module.getValue(), this.module.getDisplay(), false);
    }
  }
  dodajSlup(){
    if(this.GrupaKrokow.CzyAktywny(this.i)){
      this.control.pergola.dodajSlup();
    }
  }
  openImgDialog() {
    
    if(this.FormData.wczytywanie != true){
      this.FormData.wczytywanie = true;
      this.formService.getImg(this.FormData, this.control).pipe(/*take(1)*/).subscribe(data => {
        this.createImageFromBlob(data);
        const dialogRef = this.dialog.open(this.imgDialog,  { minWidth: '1080px'});
        // dialogRef.afterClosed().subscribe((result) => {
        //    // console.log(`Dialog result: ${result}`);
        // }); 
        this.FormData.wczytywanie = false;
      }, error => {
        this.control.src = '';
        this.FormData.wczytywanie = false;
      });
    }
    
  }


 

  public TypeToNumber(Typ:String){
    switch(Typ){
        case TypEnum.Header:
            return 0;
        break;
        case TypEnum.ModuleHeader:
            return 1;
        break;
        case TypEnum.Position:
            return 2;
        break;
        case TypEnum.ModuleFooter:
            return 3;
        break;
        case TypEnum.Footer:
            return 4;
        break;
    }
    return 0;
}

}
