import { Parameter } from "src/app/shared/models/form-model/models/form-controlls.model";
import { Control } from "./Control";
import { Modul } from "./Modul";
import { TypEnum } from "./TypEnum";
import { Values } from "./Values";
import { FileControl } from "src/app/shared/models/form/file-control.model";

export class KrokStep {
    Step: string;
    IsFooter: boolean;
    IsModule: boolean;
    IsModuleFooter: boolean;
    Type: string;
    TechVersion: Number;
    TechDate: any;
    ValuationVersion: Number;
    ValuationTechDate: any;
    Controlls: Control[];
    Parameters: Parameter[];
    Position:Number;
    Module:Number;

    

        constructor (krok:KrokStep, Parameters: Parameter[], Files: FileControl[]){
            this.Step = krok.Step;
            this.IsFooter = krok.IsFooter;
            this.IsModule = krok.IsModule;
            this.IsModuleFooter = krok.IsModuleFooter;
            this.Type = krok.Type;
            this.TechVersion = krok.TechVersion;
            this.TechDate = krok.Step;
            this.ValuationVersion = krok.ValuationVersion;
            this.ValuationTechDate = krok.ValuationTechDate;
            this.Controlls = [] ; //krok.Controlls;
            this.Parameters = Parameters;
            this.Position = krok.Position;
            this.Module = krok.Module;
            this.UstawKontrolki(krok.Controlls);
            this.UstawWartosci(Parameters, this.TypeToNumber(this.Type), Files);
            // console.log("parrr2", this.Parameters);
        }
        public TypeToNumber(Typ:String){
            switch(Typ){
                case TypEnum.Header:
                    return 0;
                break;
                case TypEnum.ModuleHeader:
                    return 1;
                break;
                case TypEnum.Position:
                    return 2;
                break;
                case TypEnum.ModuleFooter:
                    return 3;
                break;
                case TypEnum.Footer:
                    return 4;
                break;
            }
            return 0;
        }
        public UstawWartosci(Parameters: Parameter[], typ, Files: FileControl[]){
            this.Controlls.forEach(k => { 
                // console.log("ustawiam konrolki 2", k, Parameters);
                
                
                if(k.ControlType == "Number"){
                    if(k.DefaultValue != null){
                        k.wybrany = new Parameter() ;
                        k.wybrany.Key = k.KeyName;
                        k.wybrany.DisplayValue = k.DefaultValue;
                        k.wybrany.DisplayValuePl = k.DefaultValue;
                        k.wybrany.DisplayValueEn = k.DefaultValue;
                        k.wybrany.DisplayValueDe = k.DefaultValue;
                        k.wybrany.DisplayValueFr = k.DefaultValue;
                        k.wybrany.DisplayValueCs = k.DefaultValue;
                        k.wybrany.DisplayValueIt = k.DefaultValue; 
                        k.wybrany.IsPrint = k.IsPrint;
                        k.wybrany.Value = k.DefaultValue;
                        //if(k.wybrany.Type == null){
                            k.wybrany.Type = typ;
                        //}
                    }
                } else if(k.ControlType == "Hidden" || k.ControlType == "Label" || k.ControlType == "Text"){
                    
                    k.wybrany = new Parameter() ;
                    k.wybrany.Key = k.KeyName;
                    k.wybrany.DisplayValue = k.DefaultValue;
                    k.wybrany.DisplayValueEn = k.DefaultValue;
                    k.wybrany.DisplayValueDe = k.DefaultValue;
                    k.wybrany.DisplayValueFr = k.DefaultValue;
                    k.wybrany.DisplayValueCs = k.DefaultValue;
                    k.wybrany.DisplayValueIt = k.DefaultValue; 
                    k.wybrany.DisplayValuePl = k.DefaultValue; 
                    k.wybrany.IsPrint = k.IsPrint;
                    k.wybrany.Value = k.DefaultValue;
                    //if(k.wybrany.Type == null){
                        k.wybrany.Type = typ;
                    //}

                    if(k.ControlType == "Hidden"){
                        // console.log("hidden", k.wybrany);
                        if(k.Values != null){
                            var byl =false;
                            k.Values.forEach(val => {
                                val.Type = typ;
                                if(val.Key == k.DefaultValue){
                                    byl =true;
                                    k.wybrany = new Parameter() ;
                                    k.wybrany.Key = k.KeyName;
                                    k.wybrany.DisplayValue = val.DisplayValue;
                                    k.wybrany.DisplayValuePl = val.DisplayValuePl;
                                    k.wybrany.DisplayValueEn = val.DisplayValueEn;
                                    k.wybrany.DisplayValueDe = val.DisplayValueDe;
                                    k.wybrany.DisplayValueFr = val.DisplayValueFr;
                                    k.wybrany.DisplayValueCs = val.DisplayValueCs;
                                    k.wybrany.DisplayValueIt = val.DisplayValueIt;
                                    k.wybrany.IsPrint = k.IsPrint;
                                    k.wybrany.Value = val.Key;
                                    //if(k.wybrany.Type == null){
                                        k.wybrany.Type = typ;
                                    //}
                                } 
                                
                            }); 
                            
                            if(!byl){
                                k.Values.forEach(val => {
                                    // console.log("hidden wwwww", k.DefaultValue, val.Key);
                                    if((k.DefaultValue +"|").includes(val.Key +"|")){
                                        // console.log("jest hidden", k.DefaultValue, val.Key);
                                        k.wybrany.DisplayValue   = k.wybrany.DisplayValue.replace(val.Key, val.DisplayValue);
                                        k.wybrany.DisplayValueEn = k.wybrany.DisplayValueEn.replace(val.Key, val.DisplayValueEn);
                                        k.wybrany.DisplayValuePl = k.wybrany.DisplayValuePl.replace(val.Key, val.DisplayValuePl);
                                        k.wybrany.DisplayValueDe = k.wybrany.DisplayValueDe.replace(val.Key, val.DisplayValueDe);
                                        k.wybrany.DisplayValueFr = k.wybrany.DisplayValueFr.replace(val.Key, val.DisplayValueFr);
                                        k.wybrany.DisplayValueCs = k.wybrany.DisplayValueCs.replace(val.Key, val.DisplayValueCs);
                                        k.wybrany.DisplayValueIt = k.wybrany.DisplayValueIt.replace(val.Key, val.DisplayValueIt);
                                    }
                                    
                                }); 
                            }
                        }
                        // console.log("hidden", k.wybrany);
                    }

                
            } else if(k.ControlType == "File" || k.ControlType == "CheckFile"){
                if(k.Values != null && k.Values.length > 0){
                    var val:Values = k.Values[0]; 
                    if(val.File != null && val.File !== ''){
                        val.Type = typ;
                        k.wybrany = new Parameter() ;
                        k.wybrany.Key = k.KeyName;
                        k.wybrany.DisplayValue = val.DisplayValue;
                        k.wybrany.DisplayValuePl = val.DisplayValuePl;
                        k.wybrany.DisplayValueEn = val.DisplayValueEn;
                        k.wybrany.DisplayValueDe = val.DisplayValueDe;
                        k.wybrany.DisplayValueFr = val.DisplayValueFr;
                        k.wybrany.DisplayValueCs = val.DisplayValueCs;
                        k.wybrany.DisplayValueIt = val.DisplayValueIt;
                        k.wybrany.IsPrint = k.IsPrint;
                        k.wybrany.Value = val.Key;
                        k.wybrany.FileName = val.FileName;
                        k.wybrany.File = new FileControl();
                        
                        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
                            const byteCharacters = atob(b64Data);
                            const byteArrays = [];
                    
                            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                                const slice = byteCharacters.slice(offset, offset + sliceSize);
                    
                                const byteNumbers = new Array(slice.length);
                                for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                                }
                    
                                const byteArray = new Uint8Array(byteNumbers);
                                byteArrays.push(byteArray);
                            }
                    
                            const blob = new Blob(byteArrays, { type: contentType });
                            return blob;
                        };
                        k.wybrany.File.File = b64toBlob(val.File);
                        k.wybrany.File.FileName = val.FileName;
                        k.wybrany.File.Id = val.Key;
                        k.wybrany.File.Key = k.KeyName;
                        k.wybrany.File.type = val.FileName;
                        k.wybrany.Type = typ;
                    }
                    // console.log("file", k.wybrany);
                        
                }
            } else if(k.ControlType == "SelectMultiColumn" ) {
                if(k.ValuesMultiColumn != null){
                    k.ValuesMultiColumn.forEach(val => {
                        val.Type = typ;
                        if(val.Key == k.DefaultValue){
                            k.wybrany = new Parameter() ;
                            k.wybrany.Key = k.KeyName;
                            k.wybrany.DisplayValue = val.DisplayValue;
                            k.wybrany.DisplayValuePl = val.DisplayValuePl;
                            k.wybrany.DisplayValueEn = val.DisplayValueEn;
                            k.wybrany.DisplayValueDe = val.DisplayValueDe;
                            k.wybrany.DisplayValueFr = val.DisplayValueFr;
                            k.wybrany.DisplayValueCs = val.DisplayValueCs;
                            k.wybrany.DisplayValueIt = val.DisplayValueIt;
                            k.wybrany.Kolor = val.Kolor; 
                            k.wybrany.IsPrint = k.IsPrint;
                            k.wybrany.Value = val.Key;
                            //if(k.wybrany.Type == null){
                                k.wybrany.Type = typ;
                            //}

                        }
                    }); 
                }
            } else if(k.ControlType == "CheckSelectMultiColumn" ) {
                if(k.ValuesMultiColumn != null){
                    k.ValuesMultiColumn.forEach(val => {
                        val.Type = typ;
                        if(val.Key == k.DefaultValue){
                            k.wybrany = new Parameter() ;
                            k.wybrany.Key = k.KeyName;
                            k.wybrany.DisplayValue = val.DisplayValue;
                            k.wybrany.DisplayValuePl = val.DisplayValuePl;
                            k.wybrany.DisplayValueEn = val.DisplayValueEn;
                            k.wybrany.DisplayValueDe = val.DisplayValueDe;
                            k.wybrany.DisplayValueFr = val.DisplayValueFr;
                            k.wybrany.DisplayValueCs = val.DisplayValueCs;
                            k.wybrany.DisplayValueIt = val.DisplayValueIt;
                            k.wybrany.Kolor = val.Kolor; 
                            k.wybrany.IsPrint = k.IsPrint;
                            k.wybrany.Value = val.Key;
                            //if(k.wybrany.Type == null){
                                k.wybrany.Type = typ;
                            //}

                        }
                    }); 
                }
            } else {
                
 
                
                if(k.Values != null){
                    k.Values.forEach(val => {
                        val.Type = typ;
                        if(val.Key == k.DefaultValue){
                            k.wybrany = new Parameter() ;
                            k.wybrany.Key = k.KeyName;
                            k.wybrany.DisplayValue = val.DisplayValue;
                            k.wybrany.DisplayValuePl = val.DisplayValuePl;
                            k.wybrany.DisplayValueEn = val.DisplayValueEn;
                            k.wybrany.DisplayValueDe = val.DisplayValueDe;
                            k.wybrany.DisplayValueFr = val.DisplayValueFr;
                            k.wybrany.DisplayValueCs = val.DisplayValueCs;
                            k.wybrany.DisplayValueIt = val.DisplayValueIt;
                            k.wybrany.IsPrint = k.IsPrint;
                            k.wybrany.Value = val.Key;
                            //if(k.wybrany.Type == null){
                                k.wybrany.Type = typ;
                            //}

                        }
                    }); 
                }
            }
                     
                
            if(k.ControlType != "Hidden" && k.ControlType != "Label"){
                if(this.Parameters){
                    this.Parameters.forEach(par => {
                        if(par.Key == k.KeyName && par.Module == this.Module && par.Position == this.Position){
                            if(k.ControlType !== 'File' && k.ControlType !== 'CheckFile'){
                                // console.log("ust",par.Key,  par, k.Values);
                                k.wybrany.Type = typ;
                                k.wybrany = par;
                                if(k.Values != null){
                                    k.Values.forEach(val => {
                                        val.Type = typ;
                                        if(val.Key == k.wybrany.Value){
                                            k.wybrany = new Parameter() ;
                                            k.wybrany.Key = k.KeyName;
                                            k.wybrany.DisplayValue = val.DisplayValue;
                                            k.wybrany.DisplayValuePl = val.DisplayValuePl;
                                            k.wybrany.DisplayValueEn = val.DisplayValueEn;
                                            k.wybrany.DisplayValueDe = val.DisplayValueDe;
                                            k.wybrany.DisplayValueFr = val.DisplayValueFr;
                                            k.wybrany.DisplayValueCs = val.DisplayValueCs;
                                            k.wybrany.DisplayValueIt = val.DisplayValueIt;
                                            k.wybrany.IsPrint = k.IsPrint;
                                            k.wybrany.Value = val.Key;
                                            //if(k.wybrany.Type == null){
                                                k.wybrany.Type = typ;
                                            //}
                
                                        }
                                    }); 
                                }

                            }
                           
                        }
                    });
                }
            }
                
                
                if(k.ControlType === 'File' || k.ControlType === 'CheckFile'){
                    // console.log("z1", k);
                    // console.log("files", Files);
                    if(Files){
                        Files.forEach(file => {
                            if(file.Key == k.KeyName){
                                    k.wybrany = new Parameter();
                                    k.wybrany.File = file;
                                    

                                    if(k.Values != null && k.Values.length > 0){
                                        var val:Values = k.Values[0]; 
                                        k.wybrany.Key = k.KeyName;
                                        k.wybrany.DisplayValue = val.DisplayValue;
                                        k.wybrany.DisplayValuePl = val.DisplayValuePl;
                                        k.wybrany.DisplayValueEn = val.DisplayValueEn;
                                        k.wybrany.DisplayValueDe = val.DisplayValueDe;
                                        k.wybrany.DisplayValueFr = val.DisplayValueFr;
                                        k.wybrany.DisplayValueCs = val.DisplayValueCs;
                                        k.wybrany.DisplayValueIt = val.DisplayValueIt;
                                        k.wybrany.IsPrint = k.IsPrint;
                                        k.wybrany.Value = val.Key;
                                        // console.log("uzupelniam plik");
                                    } else {
                                        k.wybrany.Key = k.KeyName;
                                        k.wybrany.DisplayValue = "Plik wgrany";
                                        k.wybrany.DisplayValuePl = "Plik wgrany";
                                        k.wybrany.DisplayValueEn = "Plik wgrany";
                                        k.wybrany.DisplayValueDe = "Plik wgrany";
                                        k.wybrany.DisplayValueFr = "Plik wgrany";
                                        k.wybrany.DisplayValueCs = "Plik wgrany";
                                        k.wybrany.DisplayValueIt = "Plik wgrany";
                                        k.wybrany.IsPrint = k.IsPrint;
                                        k.wybrany.Value = k.KeyName;
                                    }
                                    k.wybrany.FileName = k.wybrany.File.FileName;
                            
                            }
                        });
                    }
                
                    // console.log("file2", k.wybrany);
                    // console.log("z2", k, Files);
                    if(Files == null){
                        Files = [];
                    }
                    if(k.wybrany.File != null && k.wybrany.File.File != null && k.wybrany.File.File != ''){
                        var f = Files.find(x => x.Key == k.KeyName);
                        if(f != null){
                            
                            // console.log("files2", Files);
                            // console.log("files-1", k.wybrany.File);
                            // f.File = k.wybrany.File;
                        } else {
                            
                            // console.log("files3", Files);
                            Files.push(k.wybrany.File);
                        }
                        // k.wybrany.Value = newGuid;
                        k.wybrany.File.Id = k.wybrany.Value;
                        var newGuid:String = "asadadasda asd asdadsad";
                        // console.log("files4", Files);
                        // console.log("files-2", k.wybrany);
                        // console.log("files-1", k);

                    }
                    // console.log("z3", k, Files);
                }
                
                if(k.ControlType === 'Pergola' ){
                    k.InitValuePergola();
                }


                
                // console.log('wyb', con.KeyName, con.wybrany);
            });
        }

    public UstawKontrolki(Controlls:Control[]){
        // console.log("ustawiam konrolki", Controlls);
        Controlls.forEach(con => { 
            var k = new Control(con);
            var val:Values[] = [];
            if(k.Values != null){
                k.Values.forEach(v => {
                    if(v.Disabled == null || !v.Disabled){
                        val.push(v);
                    }
                    
                });
                k.Values = val;
            }
            // console.log("kontrrr",k);
            if(k.ControlType == 'Select' && k.KeyName	 == 'IdParent'){
                k.ControlType = 'WyborParent';
                // console.log("kontrrr",k);
            }
            if(k.ControlType == 'Select' && k.KeyName	 == 'IdParentMod'){
                k.ControlType = 'WyborParentModule';
            }
            if(k.ControlType == 'Select' && k.KeyName	 == 'IdParentPoz'){
                k.ControlType = 'WyborParentPosition';
            }
            if(k.ControlType == 'Pergola'){
                 // console.log("parrr", this.Parameters);
                var p:Parameter[] = this.Parameters.filter(x => (x.Module == this.Module && (x.Position == this.Position || x.Position == 0)) || (x.Module == 0 && x.Position == 0));
                
                k.OtherParams = p;
                k.InitPergola();
            }
            this.Controlls.push(k);
            // console.log("ustawiam konrolki 2", k, con);
          /*  if(this.Parameters){
                this.Parameters.forEach(par => {
                    if(par.Key == con.KeyName){
                        k.wybrany = par;
                    }
                });
            }*/
            
            // console.log('wyb', con.KeyName, con.wybrany);
        });
    }
    public czyWidoczny(){
        var widoczny = false;
        this.Controlls.forEach(con => {
            if(con.ControlType !== 'Hidden' && con.ControlType !== 'NewLine'){
                widoczny = true;
            }
        });
        
        return widoczny;
    }

    public czyPoprawne(){
        var poprawne = true;
        // console.log(this.Controlls);

        this.Controlls.forEach(con => {
            var con_poprawne = true;

            if(con.ControlType === 'Komunikat'){
                con_poprawne = false;
                poprawne = false;
            } else if(con.ControlType === 'File'){
                var par:Parameter = con.wybrany;
                 // console.log(par, con);
               if(par.Key == 'X' || par.File == null || par.File == null){
                    con_poprawne = false;
                    poprawne = false;
                }
                var poprawne_tmp = false;
                con.Values.forEach(v => {
                    // console.log(par, par.Value, v.Key);
                    if(par.Value == v.Key){
                        poprawne_tmp = true;
                    }
                });
                con_poprawne = poprawne_tmp;
                if(!poprawne_tmp){
                    poprawne = poprawne_tmp;
                }
            } else if(con.ControlType === 'CheckFile'){
               var par:Parameter = con.wybrany;
               // console.log("check file", con);
               if(par.Key == 'CheckX'){
                    con_poprawne = false;
                    poprawne = false;
                } else if(par.Key != 'X'){
                    var poprawne_tmp = false;
                    con.Values.forEach(v => {
                        // console.log(par, par.Value, v.Key);
                        if(par.Value == v.Key){
                            poprawne_tmp = true;
                        }
                    });
                    con_poprawne = poprawne_tmp;
                    if(!poprawne_tmp){
                        poprawne = poprawne_tmp;
                    }
                }
                
            } else if(con.ControlType === 'InputNumber' || con.ControlType === 'SliderNumber' || con.ControlType === 'Number' ){
                var par:Parameter = con.wybrany;
                if(par.Key == 'X'){
                     // console.log(con);
                    con_poprawne = false;
                    poprawne = false;
                }
                var min = null;
                var max = null;
                con.Parameters.forEach(p => {
                    if(p.Key == "Min") {
                        min = p.Value;
                    } else if(p.Key == "Max") {
                        max = p.Value;
                    }
                });
                if(min != null && par.Value*1 < min*1){
                    // console.log("za małe", min, par.Value);
                    con_poprawne = false;
                    poprawne = false;
                }
                if(max != null && par.Value*1 > max*1){
                     // console.log("za duże", con, par);
                    con_poprawne = false;
                    poprawne = false;
                }

                if(isNaN(par.Value)){
                   // console.log("nie liczba", con, par);
                   con_poprawne = false;
                   poprawne = false;
               }
                
                
                // console.log("Number ??", con, par, con_poprawne, poprawne);

            } else if(con.ControlType === 'CheckInput' || con.ControlType === 'CheckNumber'){
                var par:Parameter = con.wybrany;
                // console.log("cz", con, par);
                if(par.Value === null || par.Value === '' || par.Key === 'X'){
                    // console.log("cz3", par);
                } else {
                    if(par.Key == 'X'){
                        // console.log(con);
                        con_poprawne = false;
                        poprawne = false;
                    }
                    var min = null;
                    var max = null;
                    con.Parameters.forEach(p => {
                        if(p.Key == "Min") {
                            min = p.Value;
                        } else if(p.Key == "Max") {
                            max = p.Value;
                        }
                    });
                    
                // console.log("cz2", min, max, par.Value*1);
                    if(min != null && par.Value*1 < min*1){
                        // console.log("za małe", min, par.Value);
                        con_poprawne = false;
                        poprawne = false;
                    }
                    if(max != null && par.Value*1 > max*1){
                        // console.log("za duże", con, par);
                        con_poprawne = false;
                        poprawne = false;
                    }
                } 
                    
                

            } else if(con.ControlType === 'Select' || con.ControlType === 'RadioImg' || con.ControlType === 'Check' || con.ControlType === 'ComboFoto' || con.ControlType === 'SelectImg' || con.ControlType === 'SelectModal' || con.ControlType === 'Fabric'){
                
                var par:Parameter = con.wybrany;
                 // console.log(par, con);
               if(par.Key == 'X'){
                    con_poprawne = false;
                    poprawne = false;
                }
                var poprawne_tmp = false;
                con.Values.forEach(v => {
                    // console.log(par, par.Value, v.Key);
                    if(par.Value == v.Key){
                        poprawne_tmp = true;
                    }
                });
                con_poprawne = poprawne_tmp;
                if(!poprawne_tmp){
                    poprawne = poprawne_tmp;
                }
                // console.log("select pop ", poprawne, poprawne_tmp);
            }  else if(con.ControlType === 'SelectMultiColumn'){
                
                var par:Parameter = con.wybrany;
                 // console.log(par, con);
               if(par.Key == 'X'){
                    con_poprawne = false;
                    poprawne = false;
                }
                var poprawne_tmp = false;
                con.ValuesMultiColumn.forEach(v => {
                    // console.log(par, par.Value, v.Key);
                    if(par.Value == v.Key){
                        poprawne_tmp = true;
                    }
                });
                con_poprawne = poprawne_tmp;
                if(!poprawne_tmp){
                    poprawne = poprawne_tmp;
                }
                // console.log("select pop ", poprawne, poprawne_tmp);
            } else if(con.ControlType === 'CheckSelect' ){
                
                var par:Parameter = con.wybrany;
                 // console.log(par, con);
                 if(par.Value != null && par.Value != ''){
                    if(par.Key == 'X'){
                        con_poprawne = false;
                        poprawne = false;
                    }
                    var poprawne_tmp = false;
                    con.Values.forEach(v => {
                        // console.log(par, par.Value, v.Key);
                        if(par.Value == v.Key){
                            poprawne_tmp = true;
                        }
                    });
                    con_poprawne = poprawne_tmp;
                    if(!poprawne_tmp){
                        poprawne = poprawne_tmp;
                    }
                    // console.log("select pop ", poprawne, poprawne_tmp);
                 }
               
            }  else if(con.ControlType === 'CheckSelectMultiColumn' ){
                
                var par:Parameter = con.wybrany;
                 // console.log(par, con);
                 if(par.Value != null && par.Value != ''){
                    if(par.Key == 'X'){
                        con_poprawne = false;
                        poprawne = false;
                    }
                    var poprawne_tmp = false;
                    con.ValuesMultiColumn.forEach(v => {
                        // console.log(par, par.Value, v.Key);
                        if(par.Value == v.Key){
                            poprawne_tmp = true;
                        }
                    });
                    
                    con_poprawne = poprawne_tmp;
                    if(!poprawne_tmp){
                        poprawne = poprawne_tmp;
                    }
                    // console.log("select pop ", poprawne, poprawne_tmp);
                 }
               
            } else if(con.ControlType === 'Pergola'){
                con_poprawne = con.pergola.czyPoprawne();
                    poprawne = con_poprawne;
            } else if(con.ControlType === 'Text'){
                if(con.Required){
                    var par:Parameter = con.wybrany;
                    if(par.Value == null || par.Value == ''){
                        con_poprawne = false;
                        poprawne = false;
                    }
                }
            } else if(con.ControlType === 'WyborParent' || con.ControlType === 'WyborParentModule' || con.ControlType === 'WyborParentPosition'){
             
                    var par:Parameter = con.wybrany;
                    if(par.Value == null || par.Value == ''){
                        con_poprawne = false;
                        poprawne = false;
                    }
                
            } else if(con.ControlType === 'Module'){
                // console.log("module", con);
                var par:Parameter = con.wybrany;
                if(par.Value == null || par.Value == ''){
                    con_poprawne = false;
                    poprawne = false;
                    console.log("module2", con);
                }
            } else if(con.ControlType === 'CheckCombo'){

            }
            con.poprawne = con_poprawne;
            
        });
        // console.log("poprawne", poprawne);
        return poprawne;
    }
    getParam(con:Control):Parameter{
        var wynik = new Parameter();
        this.Parameters.forEach(par => {
            // console.log(par, con);
            if(par.Key == con.KeyName){
                // console.log("wybrał, par.Key", par.Key);
                wynik =  par;
            }
        });
        return wynik;
    }
    getParamById(numer:number):Parameter{
        var wynik = new Parameter();
        this.Parameters.forEach(par => {
            // console.log(par, con);
            if(par.Key == this.Controlls[numer].KeyName){
                // console.log("wybrał, par.Key", par.Key);
                wynik =  par;
            }
        });
        return wynik;
    }
  }
