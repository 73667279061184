
export class Wneka {
    sciana = "";
    poz = 0;
    dlugosc = 0;
    pozx = 0;
    pozy = 0;
    szerokosc_w_px = 0;
    wysokosc_w_px = 0;
    checked = false;
    numer = 0;
    constructor(){
       
    }
    


}