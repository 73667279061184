import { Parameter } from "src/app/shared/models/form-model/models/form-controlls.model";
import { Values } from "./Values";
import { Pergola } from "./Pergola";
import { SafeUrl } from "@angular/platform-browser";

export class Control {
    ControlType: string;
    ControlTypeInTinterpreter: string;
    KeyName: string;
    DefaultValue: string;
    TranslateCode: string;
    DisplayInTablePosition: boolean;
    IsPrint: boolean;
    Width: Number;
    Values: Values[];
    PictureCode: string;
    PictureWidth: Number;
    NotAffectToOtherControls: Number;
    Required: boolean;
    ForegrandColour: string;
    BackgroundColour: string;
    Parameters: Param[];
    OtherParams: Parameter[];
    poprawne:boolean = true; 
    wybrany:Parameter = new Parameter();
    Hidden:boolean;
    ParentParameters:Parameter[] = [];
    public pergola: Pergola = new Pergola();
    src: SafeUrl;
    
  ValuesMultiColumn: Values[];
    constructor(control:Control){
        this.ControlType = control.ControlType;
        this.ControlTypeInTinterpreter = control.ControlTypeInTinterpreter;
        this.KeyName = control.KeyName;
        this.DefaultValue = control.DefaultValue;
        this.TranslateCode = control.TranslateCode;
        this.DisplayInTablePosition = control.DisplayInTablePosition;
        this.IsPrint = control.IsPrint;
        this.Width = control.Width;
        this.Values = control.Values;
        this.PictureCode = control.PictureCode;
        this.PictureWidth = control.PictureWidth;
        this.NotAffectToOtherControls = control.NotAffectToOtherControls;
        this.Required = control.Required;
        this.ForegrandColour = control.ForegrandColour;
        this.BackgroundColour = control.BackgroundColour;
        this.Parameters = control.Parameters;
        this.poprawne = control.poprawne;
        this.Hidden = control.Hidden;
        this.ValuesMultiColumn = control.ValuesMultiColumn;
        if(this.ControlType == "CheckSelectMultiColumn" || this.ControlType == "SelectMultiColumn"){
             this.Values = control.ValuesMultiColumn;
        }

        
        
    }
    InitPergola(){
      
      this.pergola.Init(this);
    }
    InitValuePergola(){
      
      this.pergola.InitValue(this);
    }
  }

  
export class Param {
    Disabled:boolean;
    Key: string;
    Module:number;
    ParametrKinf: number;
    ParametrKindName:string;
    Type:number;
    Value: string;
    IsPrint:boolean;
}