<div class="row mt-5 w-100">
  <div class="col-md-5 ml-auto mr-auto p-5">
    <h1>{{"wkrotce_wrocimy"|translate}}</h1>
    <div style="font-size: 1.45rem;">
      <p>{{"przepraszamy_za_niedogodnosciale"|translate}}
        <a href="mailto:bok@selt.com">{{"skontaktuj_sie_z_nami"}}</a>
        {{"w_przeciwnym_razie_wkrotce_wrocimy"|translate}}
      </p>
      <p><a href="https://www.selt.com/">&mdash;SELT</a></p>
    </div>
    <p class="mt-4" style="font-size: 0.9rem;">Site powered by
      <a href="https://www.selt.com" target="_blank"><b>Selt</b></a>
    </p>
  </div>
</div>
