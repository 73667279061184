<div class="container login">
  <div class="row pb-5">
    <div class="col-5">
      <img width="100%" src="../../assets/images/LogoSeltSVG.svg" />
    </div>
    <div class="col-7 ml-auto text-right">
      <mat-form-field class="select mw-100" appearance="outline">
        <mat-select [(value)]="Lang" (valueChange)="changeLanguage()">
          <mat-option *ngFor="let language of this.translateService.langs" [value]="language">
            {{ language | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="col-md-4 mr-auto ml-auto" [formGroup]="UserGroup" (ngSubmit)="resetPassword(UserGroup)">
        <mat-form-field  appearance="outline" color="accent" class="w-100">
          <span matPrefix>
            <mat-icon class="align-middle mr-2">account_circle</mat-icon>
          </span>
          <input matInput autocomplete="off" type="text" formControlName="Username" (keyup.enter)="resetPassword(UserGroup)" placeholder="{{ 'nazwa_uzytkownika' | translate }}" />
          <mat-error style="margin-top: -0.5em" *ngIf="UserGroup.get('Username')?.hasError('required')">
            {{ 'pole_nie_moze_byc_puste' | translate }}
          </mat-error>
        </mat-form-field>

      <!-- <mat-form-field class="w-100">
        <span matPrefix>
          <mat-icon class="align-middle mr-2">forward_to_inbox</mat-icon>
        </span>
        <input matInput type="text" [(ngModel)]="Email" placeholder="{{'haslo'|translate}}" (keyup.enter)="resetPassword()">
      </mat-form-field> -->

      <button  mat-flat-button color="primary" class="w-100 mt-3" type="submit" (click)="resetPassword(UserGroup)">
        {{ 'zresetuj_haslo' | translate }}
      </button>
      <div class="w-100 mt-2">
        <button mat-button (click)="routeTo('Login')">{{ 'masz_juz_login_i_haslo_zaloguj' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 ml-auto mr-auto text-center">
      <p>
        Powered by
        <a href="https://www.selt.com" target="_blank"><b>Selt</b></a>
      </p>
    </div>
  </div>
</div>
