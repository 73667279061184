<div class="container">
  <div class="logo" (click) = "wyloguj()">
  </div>
</div>
<div class="container">
  <div class="welcomeTitle">
    <label id="witaj_na_stronie_selta" [translation]="TranslationEnum.Translation">{{'witaj_na_stronie_selta' | translate}}</label><br />
    <label id="prosze_zmien_haslo" [translation]="TranslationEnum.Translation">{{'prosze_zmien_haslo' | translate}}</label><br />
    <label id="dziekujemy" [translation]="TranslationEnum.Translation">{{'dziekujemy' | translate}}.</label>
  </div>
  <div class=row>
    <form class="changePassword">
      <div class="form-group">
        <div class="col-md-9">
          <div class="row">
            <form #f="ngForm" [matchValue]="['password', 'confirmPassword']">
              <div class="form-group">
                <label for="password" id="nowe_hasło" [translation]="TranslationEnum.Translation">
                  {{'nowe_hasło'|translate}}
                  <!-- <app-tlumacz [id]="'Nowe hasło'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </label>
                <input type="password" class="form-control" [(ngModel)]="model.password" #password="ngModel"
                  name="password" [class.is-invalid]="f.submitted && password.invalid" required minlength="8">
                <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                  <div *ngIf="password?.errors.required" id="hasło_jest_wymagane"
                    [translation]="TranslationEnum.Translation">
                    {{'hasło_jest_wymagane'|translate}}
                    <!-- <app-tlumacz [id]="'Hasło jest wymagane'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </div>
                  <div *ngIf="password?.errors.minlength" id="hasło_musi_się_składać_przynajmniej_z_8_znaków"
                    [translation]="TranslationEnum.Translation">
                    {{'hasło_musi_się_składać_przynajmniej_z_8_znaków' |translate}}
                    <!-- <app-tlumacz [id]="'Hasło musi się składać przynajmniej z 8 znaków'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="confirmPassword" id="potwierdź_hasło" [translation]="TranslationEnum.Translation">
                  {{'potwierdź_hasło'|translate}}
                  <!-- <app-tlumacz [id]="'Potwierdź hasło'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                </label>
                <input type="password" class="form-control" [(ngModel)]="model.confirmPassword"
                  #confirmPassword="ngModel" name="confirmPassword"
                  [class.is-invalid]="f.submitted && confirmPassword.invalid" required>
                <div *ngIf="f.submitted && confirmPassword.invalid" class="invalid-feedback">
                  <div *ngIf="confirmPassword?.errors.required" id="potwierdź_hasło"
                    [translation]="TranslationEnum.Translation">
                    {{'potwierdź_hasło'|translate}}
                    <!-- <app-tlumacz [id]="'Potwierdź hasło'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </div>
                  <div *ngIf="confirmPassword?.errors.matchValueError" id="hasła_muszą_się_zgadzać"
                    [translation]="TranslationEnum.Translation">
                    {{'hasła_muszą_się_zgadzać'|translate}}
                    <!-- <app-tlumacz [id]="'Hasła muszą się zgadzać'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                  </div>
                </div>
              </div>
              <div styl="color: white;" class="form-group">
                <input name="confirmSell1" type="checkbox"
                  [class.has-error]="confirmSell1.touched && confirmSell1.invalid" [(ngModel)]="model.confirm1"
                  #confirmSell1="ngModel" required>
                <span id="potwierdzeniesprzedazy" [translation]="TranslationEnum.Translation">
                  {{'potwierdzeniesprzedazy'|translate}}</span>
                <!-- <app-tlumacz  [id]="'PotwierdzenieSprzedazy'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                <a id="link" class="ows" [translation]="TranslationEnum.Translation"
                  [href]="getLink(636)"
                  target="_blank">{{'link'|translate}}
                  <!-- <app-tlumacz  [id]="'link'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </a><br />
                <input name="confirmSell2" type="checkbox"
                  [class.has-error]="confirmSell2.touched && confirmSell2.invalid" [(ngModel)]="model.confirm2"
                  #confirmSell2="ngModel" required>
                <span id="potwierdzeniegwarancji"
                  [translation]="TranslationEnum.Translation">{{'potwierdzeniegwarancji'|translate}}</span>
                <!-- <app-tlumacz  [id]="'PotwierdzenieGwarancji'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                <a id="link" class="owg" [translation]="TranslationEnum.Translation"
                  [href]="getLink(637)"
                  target="_blank">{{'link'|translate}}
                  <!-- <app-tlumacz  [id]="'link'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </a><br />
                <input name="confirmSell3" type="checkbox"
                  [class.has-error]="confirmSell3.touched && confirmSell3.invalid" [(ngModel)]="model.confirm3"
                  #confirmSell3="ngModel" required>
                <span id="potwierdzenieregulaminu"
                  [translation]="TranslationEnum.Translation">{{'potwierdzenieregulaminu'|translate}}</span>
                <!-- <app-tlumacz  [id]="'PotwierdzenieRegulaminu'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                <a id="link" [translation]="TranslationEnum.Translation"
                  [href]="getLink(640)" rel="nofollow" target="_blank" rel="noopener">
                  {{'link'|translate}}
                  <!-- <app-tlumacz [id]="'link'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </a><br />
                <input name="confirmSell4" type="checkbox"
                  [class.has-error]="confirmSell4.touched && confirmSell4.invalid" [(ngModel)]="model.confirm4"
                  #confirmSell4="ngModel" required>
                <span id="zapoznalemrodo" [translation]="TranslationEnum.Translation">{{'zapoznalemrodo' |
                  translate}}</span>
                <!-- <app-tlumacz  [id]="'ZapoznalemRodo'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                <a id="link" class ="rodo" [translation]="TranslationEnum.Translation"
                  [href]="getLink(641)" target="_blank">
                  {{'link' |translate}}
                  <!-- <app-tlumacz  [id]="'link'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </a><br />
                <input name="confirmSell5" type="checkbox"
                  [class.has-error]="confirmSell5.touched && confirmSell5.invalid" [(ngModel)]="model.confirm5"
                  #confirmSell5="ngModel" required>
                <span id="zapoznalemochornaprawautorskich" [translation]="TranslationEnum.Translation">
                  {{'zapoznalemochornaprawautorskich'|translate}}</span>
                <!-- <app-tlumacz  [id]="'ZapoznalemOchornaPrawAutorskich'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                <a id="link" [translation]="TranslationEnum.Translation"
                  [href]="getLink(642)"
                  target="_blank">{{'link'|translate}}
                  <!-- <app-tlumacz  [id]="'link'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </a><br />
                <span
                  *ngIf="confirmSell1.errors?.required || confirmSell2.errors?.required || confirmSell3.errors?.required || confirmSell4.errors?.required || confirmSell5.errors?.required"
                  id="wymaganazgoda" [translation]="TranslationEnum.Translation" style="color: red;">
                  {{'wymaganazgoda'| translate}}
                  <!-- <app-tlumacz  [id]="'WymaganaZgoda'" [typ]="'stale'" [tresci]="tresci" ></app-tlumacz> -->
                </span>
              </div>
              <button id="zmień_hasło" class="btn btn-danger btn-lg btn-block" [translation]="TranslationEnum.Translation"
                type="submit" (click)="onSubmit(f)">
                {{'zmień_hasło' |translate}}
                <!-- <app-tlumacz [id]="'Zmień hasło'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </button>

            </form>
          </div> 
          <div class="footer">
            <div class="container">
              <div class="row">
                <div class="col-sm-4"></div>
                <div class="col-sm-4">
                  <hr>
                  <div class="text-center">
                    <span>Powered by
                      <a style="color: inherit; text-decoration: none; text-transform: none;" href="https://www.selt.com" target="_blank"><b>Selt</b></a>
                    </span>
                  </div>
                </div>
                <div class="col-sm-4"></div>
              </div>
            </div>
