


<div class="belka_gorna" style="background-color: #0000cd;">
  <div class="d-flex">
    <h1 class="p-2 ml-auto mr-auto w-100 text-white">
      <span id="{{SystemName}}" [translation]="TranslationEnum.TranslationForm">{{SystemName|translate}}</span>
    </h1>
    <p class="pr-3 text-white ml-auto mt-auto" style="font-size: 12px;">F{{FormData.TechVersion}}</p>
  </div>
</div>


<div *ngIf="wczytywanie || FormData.zapisuje || FormData.supervisor.wczytywanieAuto"
  class="mt-5 d-flex justify-content-center align-items-center bg-white">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>
<div *ngIf="wczytywanie || FormData.supervisor.wczytywanieAuto" class="wczytywanie_text">
  <div id="ladowanie" [translation]="TranslationEnum.TranslationForm">
    {{'ladowanie'|translate}}
  </div>
  <!--<span id="{{FormData.LoadingName}}" [translation]="TranslationEnum.TranslationForm">
    {{FormData.LoadingName|translate}}
  </span>
  {{FormData.IloscWczytana}}/{{FormData.ModuleCount}} -->
  <div id="proszeczekac" [translation]="TranslationEnum.TranslationForm" >
    {{'proszeczekac'|translate}}
  </div>
  <div id="proszeczekac2" *ngIf = "FormData.supervisor.wczytywanieAuto" >
    <span *ngIf="FormData.supervisor.aktualnyTyp == 'Header'"  id="wczytuje_header" [translation]="TranslationEnum.TranslationForm">{{'wczytuje_header'|translate}}</span> 
    <span *ngIf="FormData.supervisor.aktualnyTyp == 'ModuleHeader' || FormData.supervisor.aktualnyTyp == 'Position' || FormData.supervisor.aktualnyTyp == 'ModuleFooter'">
      <span  id="wczytano_mod" [translation]="TranslationEnum.TranslationForm">{{'wczytano_mod'|translate}}</span> {{FormData.supervisor.getAktualnyMod()}}/{{FormData.moduly.length}} <span  id="liczba_modulow" [translation]="TranslationEnum.TranslationForm">{{'liczba_modulow'|translate}}</span>
    </span> 
    <div  *ngIf="FormData.supervisor.aktualnyTyp == 'Position'">{{FormData.supervisor.getAktualnaPoz()}}  <span  id="liczba_pozycja" [translation]="TranslationEnum.TranslationForm">{{'liczba_pozycja'|translate}}</span></div>
    <span *ngIf="FormData.supervisor.aktualnyTyp == 'Footer'"  id="wczytuje_akcesoria" [translation]="TranslationEnum.TranslationForm">{{'wczytuje_akcesoria'|translate}}</span>
  </div>
  
</div>




<div class="container"  *ngIf="!wczytywanie  && !FormData.supervisor.wczytywanieAuto && !FormData.zapisuje && FormData.TechVersion == null">
  <div class="wczytaj_zapisane" *ngIf = "zapisaneParametry.length > 0 && !wczytywanie">
    <div class="button_wczytaj_zapisane"><span id="czy_wczytac_zapisane" [translation]="TranslationEnum.TranslationForm">{{ 'czy_wczytac_zapisane' | translate }}</span></div>
    <div class="button2_wczytaj_zapisane mat-flat-button" (click) = "wczytajZapisane()"><span id="czy_wczytac2_zapisane" [translation]="TranslationEnum.TranslationForm">{{ 'czy_wczytac2_zapisane' | translate }}</span></div>
    <div class="button2_wczytaj_zapisane mat-flat-button" (click) = "usunZapisane()"><span id="czy_usunac_zapisane" [translation]="TranslationEnum.TranslationForm">{{ 'czy_usunac_zapisane' | translate }}</span></div>
  </div>
</div>

<div class="container"  *ngIf="!wczytywanie && !FormData.supervisor.wczytywanieAuto && !FormData.zapisuje && (tresci.DevMode || contractor == 13802)">
  <div *ngIf = "FormData.naglowek.kroki.length > 0" class="button2_wczytaj_zapisane mat-flat-button" (click) = "zapiszDoJSON()"><span id="zapisz_do_json" [translation]="TranslationEnum.TranslationForm">{{ 'zapisz_do_json' | translate }}</span></div>
  <div *ngIf = "FormData.naglowek.kroki.length > 0" class="button2_wczytaj_zapisane mat-flat-button" (click) = "zapiszDoXML()"><span id="zapisz_do_xml" [translation]="TranslationEnum.TranslationForm">{{ 'zapisz_do_xml' | translate }}</span></div>
   <div class="wczytaj_plik" *ngIf = "!FormData.CzyWczytane && FormData.kindOper == 0 && FormData.TechVersion == null">
    <mat-icon>save_alt</mat-icon><span id="wczytaj_plik" [translation]="TranslationEnum.TranslationForm" >{{ 'wczytaj_plik' | translate }}</span>
    <input class="wczytaj_plik2"
    #input
    type="file"
    (change)="changeJSON($event)"
  />
   </div>
    

</div>

<div class="container pozycja_edit_{{FormData.supervisor.edycja_pozycji}}"  *ngIf="!wczytywanie && !FormData.supervisor.wczytywanieAuto && !FormData.zapisuje">

  <button class="float-right " style="position: sticky; top: 118px; z-index: 999; padding-top: 10px;" mat-icon-button color="primary" (click)="returnToOrders()">
    <mat-icon>disabled_by_default</mat-icon>
  </button>
  
  <div class="grupa_tab"  *ngIf="!wczytywanie && !FormData.wczytywanie && !FormData.supervisor.wczytywanieAuto">
      <div class="tab tab{{FormData.supervisor.zakladka == 0}}" (click) = "FormData.ZmienZakladke(0, formService,tresci, dialog)">
          <span id="naglowek" [translation]="TranslationEnum.TranslationForm">
              {{'naglowek' | translate}}
            </span>
      </div>
      <div class="tab tab{{FormData.supervisor.zakladka == 1}}" *ngIf = "FormData.naglowek.CzyZakonczony()" (click) = "FormData.ZmienZakladke(1, formService,tresci, dialog)">
          <span id="dane_modułów" [translation]="TranslationEnum.TranslationForm">
              {{'dane_modułów' | translate}}
            </span>
      </div>
      <div class="tab tab{{FormData.supervisor.zakladka == 2}}" (click) = "FormData.ZmienZakladke(2, formService,tresci, dialog)" *ngIf = "CzyJestFooter() && FormData.naglowek.CzyZakonczony()">
          <span id="dane_stopki" [translation]="TranslationEnum.TranslationForm">
              {{'dane_stopki' | translate}}
            </span>
      </div>
  </div>
      
  <div class="grupa_tab"  *ngIf="!wczytywanie && FormData.wczytywanie && !FormData.supervisor.wczytywanieAuto">
      <div class="tab tab_deactive">
          <span id="naglowek" [translation]="TranslationEnum.TranslationForm">
              {{'naglowek' | translate}}
            </span>
      </div>
      <div class="tab tab_deactive" *ngIf = "FormData.naglowek.CzyZakonczony()" >
          <span id="dane_modułów" [translation]="TranslationEnum.TranslationForm">
              {{'dane_modułów' | translate}}
            </span>
      </div>
      <div class="tab tab_deactive" *ngIf = "CzyJestFooter() && FormData.naglowek.CzyZakonczony()">
          <span id="dane_stopki" [translation]="TranslationEnum.TranslationForm">
              {{'dane_stopki' | translate}}
            </span>
      </div>
  </div>

  

  <div *ngIf="!wczytywanie && !FormData.supervisor.wczytywanieAuto && FormData.supervisor.zakladka == 0" class="header_box" >
    <app-form-delivery  [FormData] = "FormData"></app-form-delivery>
    <app-form-step [FormData] = "FormData" [module] = "0" [position] = "0" [typ] = "'Header'" [GrupaKrokow] = "FormData.naglowek" [Poprzednie] = "true"  *ngIf = "FormData.CzyWczesniejszeUzupelnione('Header')" ></app-form-step>
       
    
    <button mat-flat-button color="primary" *ngIf = "FormData.naglowek.CzyZakonczony()"  class="float-right mr-3 dalej_stopka"  (click) = "FormData.ZmienZakladke(1, formService,tresci, dialog)"
    id="dalej" [translation]="TranslationEnum.TranslationForm">
    {{'dalej'|translate}}
  </button>

  </div>
  
  <div *ngIf="!wczytywanie && !FormData.supervisor.wczytywanieAuto && FormData.supervisor.zakladka == 1 && FormData.naglowek.CzyZakonczony()" class="module_box" >
    <div class="modul_box" *ngIf = "FormData.IsModule">
      <div class="menu_modul">
        <ng-container *ngFor="let modul of FormData.moduly; let m = index;">
          <div class="button_zestaw poprawny_{{CzyPoprawnyModul(m + 1)}} zestaw_{{FormData.supervisor.getAktualnyMod() - 1 == m}}" (click) = "FormData.ZmienModul(m)">
            <span id='zestaw' [translation]="TranslationEnum.TranslationForm">{{'zestaw' | translate}} </span>
            <span class="ml-1"> {{m + 1}}</span>
          </div>
        </ng-container>
        <div class="button_zestaw dodaj_zestaw"  *ngIf = "CzyPoprawnyModul(FormData.supervisor.getAktualnyMod())" (click) = "FormData.dodajModul(formService,tresci, dialog)">&nbsp;
          <mat-icon>add_circle_outline</mat-icon>
        </div>
      </div>
        <div class="menu_modul2">
          <button class="akt_button" 
            id="usun_zestaw" [translation]="TranslationEnum.TranslationForm"  (click) = "UsunModul()">
            {{'usun_zestaw' | translate}}
          </button>
          <button class="akt_button"  
            id="kopiuj_zestaw"  *ngIf = "CzyPoprawnyModul(FormData.supervisor.getAktualnyMod())" [translation]="TranslationEnum.TranslationForm"  (click) = "FormData.KopiujModul()">
            {{'kopiuj_zestaw' | translate}}
          </button>
        </div>

        <div class="menu_modul3" *ngIf = "false">
          <button class="akt_button weryfikuj" 
            id="weryfikuj" [translation]="TranslationEnum.TranslationForm"  (click) = "Weryfikuj()">
            {{'weryfikuj' | translate}}
          </button>
          <button class="akt_button weryfikuj"  
            id="weryfikuj_wszystko" [translation]="TranslationEnum.TranslationForm"  (click) = "WeryfikujWszystko()">
            {{'weryfikuj_wszystko' | translate}}
          </button>
        </div>
    </div>
      
   

    <ng-container *ngFor="let modul of FormData.moduly; let m = index;">
      <div class="modul" *ngIf="FormData.supervisor.getAktualnyMod() - 1 == m">
        
        <app-form-step [FormData] = "FormData" [module] = "m+1" [position] = "0" [typ] = "'ModuleHeader'" [GrupaKrokow] = "FormData.moduly[m].moduleNg"  [Poprzednie] = "FormData.czyPoprawneDelivery()" ></app-form-step>
        <div class="pozycje_box" *ngIf = "FormData.moduly[m].CzyNgPoprawny()">
          <div class="pozycje_tabela" *ngIf="!FormData.supervisor.edycja_pozycji">
            <div class="tabela_box">
              <table class="pozycje_tab">
                <tr class="header">
                  <th class="headcol2" id = "edytuj_out" >
                    <span class="headcol2" id = "edytuj" [translation]="TranslationEnum.TranslationForm">{{'edytuj'|translate}}</span>
                  </th>
                  <th class="headcol3" id = "usun_out" >
                    <span class="headcol3" id = "usun" [translation]="TranslationEnum.TranslationForm">{{'usun'|translate}}</span>
                  </th>

                  <td id = "lp" [translation]="TranslationEnum.TranslationForm">{{'lp'|translate}}</td>
                  <ng-container *ngFor="let ng of FormData.moduly[m].GetNg(); let i = index;">
                    <td  id="label_t_{{ng.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_t_' + ng.toLowerCase())|translate}}</td>
                  </ng-container>
                </tr>
                <ng-container *ngFor="let pozycja of FormData.moduly[m].pozycje; let p = index;">
                  <tr class="poprawny_{{pozycja.CzyZakonczony()}}">
                    
                  <th class="headcol2" >
                    <button mat-icon-button  color="accent"  *ngIf = "!FormData.moduly[m].zakonczono_pozycje"
                    (click)="FormData.EdytujPozycje(tresci, formService, false, m, p, dialog)">
                    <mat-icon>create</mat-icon>
                  </button>

                  </th>
                  <th class="headcol3" >
                    <button  *ngIf = "!FormData.moduly[m].zakonczono_pozycje && !FormData.czyAutoModuly()" mat-icon-button (click)="FormData.UsunPozycje(tresci, formService, false, m, p, dialog) " color="accent">
                      <mat-icon >delete_outline</mat-icon>
                    </button>
                  </th>
                  <td >{{p + 1}}</td>
                    <ng-container *ngFor="let ng of FormData.moduly[m].GetNg(); let i = index;">
                      <td>{{pozycja.GetValue(ng)}}</td>
                    </ng-container>
                  </tr>
                </ng-container>
              </table>
              
            </div>
            <div  *ngIf = "!FormData.moduly[m].zakonczono_pozycje && FormData.czyMoznaDodacPozycje() && !FormData.czyAutoModuly()">
              <button mat-flat-button color="primary"
              class="mr-3"
              (click)="FormData.DodajPozycje(tresci, formService, false, m, dialog)" id="dodaj_pozycje" [translation]="TranslationEnum.TranslationForm">
              {{'dodaj_pozycje'|translate}}
            </button>
            
            


            </div>
            <div  *ngIf = "FormData.moduly[m].zakonczono_pozycje">
              <button mat-flat-button color="primary"
                class="mr-3"
                (click)="FormData.WrocDoEdycjiPoz(m)" id="edytuj_pozycje" [translation]="TranslationEnum.TranslationForm">
                {{'edytuj_pozycje'|translate}}
              </button>
            </div>
          </div>

          
          <div class="pozycja_box" *ngIf="FormData.supervisor.edycja_pozycji">
            <div class="pozycja_edit2">
              <div class="pezycje_in">
                <button mat-icon-button color="primary" class="float-right mr-3" (click)="FormData.supervisor.WyjdzZEdycji(p)">
                  <mat-icon>close</mat-icon>
                </button>
                <ng-container *ngFor="let pozycja of FormData.moduly[m].pozycje; let p = index;"> 
                  <ng-container *ngIf ="p == FormData.supervisor.getAktualnaPoz() - 1">
                    <h2>
                      <span id="dodaj" [translation]="TranslationEnum.TranslationForm">{{'dodaj' | translate}}</span>
                      <span> {{p+1}}</span>
                      <span id="pozycje_dod" [translation]="TranslationEnum.TranslationForm"> {{'pozycje_dod' | translate}}</span>
                    </h2>
                  
                    <div class="pozycja">
                        <app-form-step [FormData] = "FormData" [module] = "m+1" [position] = "p+1" [typ] = "'Position'" [GrupaKrokow] = "FormData.moduly[m].pozycje[p]"  *ngIf = "FormData.CzyWczesniejszeUzupelnione('Position')"></app-form-step>
                        
                        <button mat-flat-button color="primary" [hidden]="loadPositionForVerify" class="float-right mr-3" (click)="FormData.supervisor.ZakonczPozycje(p)" *ngIf = "FormData.moduly[m].pozycje[p].CzyZakonczony()"
                id="zapisz_pozcyje" [translation]="TranslationEnum.TranslationForm">
                {{'zapisz_pozcyje'|translate}}
              </button>
  
                    
                    </div>
                  </ng-container>
                    
                </ng-container> 
              </div>
              
            </div>
          </div>
          


          <div *ngIf = "FormData.moduly[m].CzyPozPoprawny()">
            
            <button mat-flat-button color="primary"   *ngIf = "!FormData.moduly[m].zakonczono_pozycje  && (!FormData.czyMoznaDodacPozycje() || FormData.czyZakonczJakMozeszDodac()) && FormData.czyWielomodOK()"
              (click)="FormData.moveToModuleFooter(m, formService, tresci, dialog)" id="zakoncz_dodawanie_pozycji" [translation]="TranslationEnum.TranslationForm">
              {{'zakoncz_dodawanie_pozycji'|translate}}
            </button>
            <div *ngIf = "!FormData.czyWielomodOK()" class="info_pozycje">
              <span id="info_za_duzo_pozycji" [translation]="TranslationEnum.TranslationForm">{{'info_za_duzo_pozycji'|translate}}</span>
            </div>
            <div *ngIf = "FormData.moduly[m].zakonczono_pozycje">


              
              <app-form-step [FormData] = "FormData" [module] = "m+1" [position] = "0" [typ] = "'ModuleFooter'" [GrupaKrokow] = "FormData.moduly[m].moduleFooter"  *ngIf = "FormData.CzyWczesniejszeUzupelnione('ModuleFooter')"></app-form-step>
            
            
              <button mat-flat-button color="primary" class="mr-3"  *ngIf = "CzyPoprawnyModul(FormData.supervisor.getAktualnyMod()) && FormData.IsModule" (click) = "FormData.dodajModul(formService,tresci, dialog)"
                id="dodaj_zestaw" [translation]="TranslationEnum.TranslationForm" >
                {{'dodaj_zestaw'|translate}}
              </button>

            <button mat-flat-button color="primary" *ngIf = "CzyjestNextModule() && CzyPoprawnyModul(FormData.supervisor.getAktualnyMod()) && FormData.IsModule"  class="float-right mr-3 dalej_stopka"  (click) = "FormData.nextModule()"
              id="nastepny_modul" [translation]="TranslationEnum.TranslationForm">
              {{'nastepny_modul'|translate}}
            </button>  
            <button mat-flat-button color="primary" *ngIf = "!CzyjestNextModule() && CzyJestFooter()"  class="float-right mr-3 dalej_stopka"  (click) = "FormData.ZmienZakladke(2, formService,tresci, dialog)"
              id="dalej" [translation]="TranslationEnum.TranslationForm">
              {{'dalej'|translate}}
            </button>
              

            </div>
          </div>
            
        </div>
        
      </div>

    </ng-container>
  </div>
  <div *ngIf="!wczytywanie && !FormData.supervisor.wczytywanieAuto && FormData.supervisor.zakladka == 2"  class="add_box">
      <app-form-step [FormData] = "FormData" [module] = "0" [position] = "0" [typ] = "'Footer'"  [GrupaKrokow] = "FormData.stopka" ></app-form-step>
  </div>
  <div  *ngIf="!wczytywanie && FormData.supervisor.zakladka != 0 && !FormData.CzyPoprawnyAdres()"> 
    <span id="niepoprawny_adres" [translation]="TranslationEnum.TranslationForm">{{'niepoprawny_adres'|translate}}</span>
  </div>
  <div>
    <button *ngIf="FormData.supervisor.zakladka != 0 && !wczytywanie && FormData.CzyPoprawnyAdres() && !FormData.supervisor.wczytywanieAuto && FormData.CzyModulyZakonczone()" mat-flat-button color="primary" class="mr-2" (click)="FormData.quickSave(formService, dialog)"
      id="zapisz_tymczasowo" [translation]="TranslationEnum.TranslationForm">
      {{'zapisz_tymczasowo'|translate}}</button>
    
      <button  *ngIf="FormData.supervisor.zakladka != 0 && !wczytywanie && FormData.CzyPoprawnyAdres() && !FormData.supervisor.wczytywanieAuto && FormData.stopka.CzyZakonczony() && FormData.CzyModulyZakonczone()" mat-flat-button color="primary" (click)="FormData.save(formService, dialog)"
        id="zapisz_zamowienie" [translation]="TranslationEnum.TranslationForm">
        {{'zapisz_zamowienie'|translate}}
      </button>
  </div>

</div>


<div class="devmode" *ngIf = "tresci.DevMode">
  <div>
    <button mat-flat-button color="primary" class="mr-2"  (click) = "showTechnologyValuation()"
    id="wyniki_technologi">
    Wyniki Technologii</button>
    
  </div>
  <div class="dev_dane"><div class="name">aktualnyTyp</div><div class="wartosc">{{FormData.supervisor.aktualnyTyp}} </div></div>
  <div class="dev_dane"><div class="name">Aktualny mod</div><div class="wartosc">{{FormData.supervisor.getAktualnyMod()}}</div></div>
  <div class="dev_dane"><div class="name">Aktualna poz</div><div class="wartosc">{{FormData.supervisor.getAktualnaPoz()}}</div></div>
  <div class="dev_dane"><div class="name">bez zmian</div><div class="wartosc">{{FormData.supervisor.bez_zmian}}</div></div>
  <div class="dev_dane" *ngIf = "FormData.moduly != null"><div class="name">FormData.moduly.length</div><div class="wartosc">{{FormData.moduly.length}}</div></div>

  
  <div class="dev_dane"><div class="name">Auto</div><div class="wartosc">{{FormData.supervisor.auto}} {{FormData.supervisor.wczytywanieAuto}} <span class="button_mini" (click) = "FormData.supervisor.auto = 0;">wylacz</span>/<span class="button_mini" (click) = "FormData.supervisor.wczytywanieAuto = !FormData.supervisor.wczytywanieAuto;">wylacz</span></div></div>
  <div class="dev_dane"><div class="name">wczytywanie</div><div class="wartosc">{{FormData.wczytywanie}} <span class="button_mini" (click) = "FormData.wczytywanie = false;">wylacz</span></div></div>
  <span *ngIf = "FormData.AktualneKroki() != null">
    <div class="dev_dane"><div class="name">max poz (poz)</div><div class="wartosc">{{FormData.AktualneKroki().max_p}} ({{FormData.AktualneKroki().akualnyNumer}})</div></div>
  </span>
 
  
  <button mat-flat-button color="primary" class="mr-2"  (click) = "logTMP()"
    id="wyniki_technologi">
    LOG TMP</button>
  <div *ngIf = "tresci.ostatnieParametry != null && tresci.ostatnieParametry.length > 0">
    <button mat-flat-button color="primary" class="mr-2"  (click) = "otworzDev(tresci.ostatnieParametry)"
    id="wyniki_technologi">
    Ostatnie wysłane parametry</button>
  </div>
  <div *ngIf = "FormData.ostatniKrok != null">
    <button mat-flat-button color="primary" class="mr-2"  (click) = "otworzDev(FormData.ostatniKrok)"
    id="wyniki_technologi">
    Ostatni otrzymany krok</button>
  </div>
  <div *ngIf = "FormData.Files != null && FormData.Files.length > 0">
    
    Pliki ({{FormData.Files.length}}) <br />
    {{FormData.Files[0].Id}} <br />
    {{FormData.Files[0].FileName}} <br />
    {{FormData.Files[0].Key}} <br />
      {{FormData.Files[0].Module}} <br />
        {{FormData.Files[0].Position}} <br />
          {{FormData.Files[0].Check}} <br />
  </div>
  <div> 
    
  </div>
  
  
  
</div>

