import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DynamicFormComponent } from 'src/app/new-form/initial-form/dynamic-form/dynamic-form.component';
import { KindOper } from 'src/app/shared/enums/kind-oper-enum';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { OrderList } from 'src/app/shared/models/order-register/order-list/order-list.model';
import { OrderEditDialogComponent } from '../order-list/order-edit-dialog/order-edit-dialog.component';

@Component({
  selector: 'app-child-list',
  templateUrl: './child-list.component.html',
  styleUrls: ['./child-list.component.scss']
})
export class ChildListComponent implements OnInit {
  public orders:OrderList[] = [];
  public KindOper = KindOper;
  public TranslationEnum = TranslationEnum;
   public DisplayedColumns = [
    'B2BId',
    'Produkt',
    'System',
    'Quantity',
    'Description',
    'ValueNetto',
    'DeliveryCostNetto',
    'OrderStatusName',
    'OrderDate',
    'OrderDateCreate',
    'OrderingName',
    'Edit'
  ]; 
  IsTrader: boolean = false;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<DynamicFormComponent>, @Inject(MAT_DIALOG_DATA) public data, private router: Router) {
    // console.log("data", data.OrderList);
    this.orders = data.OrderList;
    this.IsTrader = data.IsTrader;
  }
  routeToKindoperOrder(kindOper: KindOper, row) {
    // console.log("row", row);
    if(kindOper === KindOper.Edit) {
      if(this.IsTrader && row.IsTrader == false){
        let dialogRef: MatDialogRef<OrderEditDialogComponent, any>;
        dialogRef = this.dialog.open(OrderEditDialogComponent, { data: { row: row, kindOper: kindOper, version: 'NOrder' } });
        this.dialogRef.close();
      } else {
        this.router.navigate([`Order/${KindOper[kindOper]}/${row.Prefix}/${row.Id}`]);
        this.dialogRef.close();
      } 
    } else if(kindOper === KindOper.Preview) {
        this.router.navigate([`Order/${KindOper[kindOper]}/${row.Prefix}/${row.Id}`]);
        this.dialogRef.close();

    }
  }
  ngOnInit(): void {
  }

}
