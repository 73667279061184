import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from '../module-login/authorization.service';
import { UserPermission } from '../shared/models/user/user-permission.model';
import { User } from '../shared/models/user/user.model';
import { TresciService } from '../tresci.service';
import { UserService } from '../user.service';
import { DateAdapter } from '@angular/material/core';
import { PermissionEnum } from 'src/app/shared/enums/permission-enum';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Contractor } from 'src/app/shared/models/user/contractor.model';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { ReklamacjeService } from './reklamacje.service';
import { Complaints } from '../shared/models/reklamacje/Complaints.model'
import { DataSharingService } from '../shared/service/data-sharing.service';
import { merge, Subscription } from 'rxjs';
import { catchError, finalize, map, takeWhile } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatSort, Sort} from '@angular/material/sort';
import { Status } from '../shared/models/reklamacje/Status.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UniversalDialogComponent } from '../shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from '../shared/models/dialog/dialog-data.model';
@Component({
  selector: 'app-reklamacje',
  templateUrl: './reklamacje.component.html',
  styleUrls: ['./reklamacje.component.scss']
})
export class ReklamacjeComponent implements OnInit, OnDestroy {


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild('statusGroup') statusGroup;
  private subscriptions: Subscription[] = [];
  

  public TranslationEnum = TranslationEnum;
  public Range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  
  public currentPage = 0;
  public pageSize = 10;
  public dataSource;

  public DisplayedColumns: string[];
  public ResultsLength = 0;
  public IsLoadingResults = true;
  public IsRateLimitReached = false;
  public User: User;
  public EditingOrderspermission: UserPermission;
  public culture: string;
  private contractorNumber = '';
  public complaints:Complaints[];
  public statusy:Status[];
  public status:Status;
  public columndefs : any[] = ['ComplaintDate','ComplaintNumber', 'B2BNumber', 'InvoiceNumber','Status','ConcernsOrder', 'ProductionNumber', 'ShortDescription', 'IsAttachment', 'Pokaz', 'CancelEnable'];
  constructor(private reklamacjeService: ReklamacjeService,public dialog: MatDialog,private _adapter: DateAdapter<any>
    ,private dataSharingService: DataSharingService,public tresci: TresciService,private _changeDetectorRef: ChangeDetectorRef, public user: UserService,private authorizationService: AuthorizationService, 
    translationService: TranslateService, private route: ActivatedRoute, private router: Router) {
      this.subscriptions.push(this.authorizationService.User.subscribe((user) => {
      this.User = user;
      
      // this.EditingOrderspermission = this.authorizationService.hasPermission(PermissionEnum.EditingOrders);
    }));
    this.culture = window['culture'];
    this._adapter.setLocale(this.culture);

    this.subscriptions.push(translationService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.culture = window['culture'];
      this._adapter.setLocale(this.culture);
      this._changeDetectorRef.detectChanges();
    }));

    const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;

    this.contractorNumber = contractor.ContractorNumber;


  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }
  zmienKarte($event){
    this.status = this.statusy[$event.index];
    this.IsLoadingResults = true;
    this.Init2();
  }

  NazwaTlumacz(nazwa:string){
    var x = nazwa.toLowerCase();
    x = x.replace(" ", "");
    return x;
  }

  Init(){
    this.IsLoadingResults = true;
    this.subscriptions.push(this.reklamacjeService.GetComplaintStatus().
    subscribe((data) => {
      this.statusy = data
      if(this.statusy.length > 0){
        this.status = this.statusy[0];
      }
      this.Init2();
    }));
  }
  Init2(){
    
    
    this.subscriptions.push(this.reklamacjeService.GetContractorComplaints(this.contractorNumber, this.culture).pipe(
      finalize(() => {
        this.IsLoadingResults = false;

      })
    ).
    subscribe((data) => {
      data = data.filter(x => x.Status == this.status.Name);
      // data = data.find(x => x.Status = this.status.Name);
      this.complaints = data;
      this.dataSource = new MatTableDataSource<Element>(data);
      this.dataSource.paginator = this.paginator; 
      
      this.ResultsLength = this.complaints.length;
      // this.complaints = data; 
      this.dataSource.sort = this.empTbSort; 
      
      // this.iterator();
    }));

    

  }
  ngOnInit(): void {
    this.Init();
  }
  
  ngAfterViewInit(): void {
    this.subscriptions.push(this.dataSharingService.contractorChanged.subscribe((x) => {
      if (x) {
        const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
        this.contractorNumber = contractor.ContractorNumber;
        this.statusGroup.selectedIndex = 0;
        this.Init();

      }
      
    }));
  }
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    // this.iterator();
  }
  
  public Show(row:Complaints){
    // console.log(row);
    this.router.navigate(['Reklamacje/' + row.Id]);
  }
  public Anuluj(row:Complaints){
    // console.log(row);

    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
    const data: DialogData = {
      title: '',
      content: 'czy_na_pewno_chcesz_anulowac_pozycje',
      answerOk: false,
    };
    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.IsLoadingResults = true;
        this.subscriptions.push(this.reklamacjeService.CancelComplaint(row.Id).pipe(
          finalize(() => {
            this.Init2();

          })
        ).
        subscribe((data) => {
          this.router.navigate(['Reklamacje']);
        }));
 
      }
    }));

    
    
  }
  
  public Dodaj() {
    this.router.navigate(['Reklamacje/Dodaj']);
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.complaints.slice(start, end);
    // this.dataSource = part;
  }
  
}



