<h2><span id="ustawienia_slupa_{{pergola.slupy[pergola.edytuj].ktory_rog}}" [translation]="TranslationEnum.Translation">{{'ustawienia_slupa_'+pergola.slupy[pergola.edytuj].ktory_rog|translate}}</span>&nbsp;<span *ngIf = "pergola.slupy[pergola.edytuj].ktory_rog == 0"> {{(pergola.edytuj - 3)}}</span></h2>

<ng-container *ngIf = "pergola.edytuj >= 0 && pergola.edytuj < pergola.slupy.length">
    <button *ngIf = "pergola.slupy[pergola.edytuj].ktory_rog == 0" mat-flat-button  type="button" color="accent" id="usun_slup" class="btn-sm float-right"
    [translation]="TranslationEnum.TranslationForm" (click) = "usunSlup()">
    {{'usun_slup'|translate}}
    </button>

    <div class="pole_edycji" *ngIf = "pergola.slupy[pergola.edytuj].ktory_rog == 0 && (pergola.slupy[pergola.edytuj].jakaStrona(pergola) == 2 || pergola.slupy[pergola.edytuj].jakaStrona(pergola) == 4)">
      <div class="nazwa"><span id="połozeniex" [translation]="TranslationEnum.Translation">{{'połozeniex'|translate}}</span></div>
      <div class="wartosc"><input  name="pozx"  [(ngModel)]="pergola.slupy[pergola.edytuj].pozx" (change)="sprawdz(pergola.slupy[pergola.edytuj])"> mm</div>
  </div>
    <div class="pole_edycji" *ngIf = "pergola.slupy[pergola.edytuj].ktory_rog == 0 && (pergola.slupy[pergola.edytuj].jakaStrona(pergola) == 1 || pergola.slupy[pergola.edytuj].jakaStrona(pergola) == 3)">
        <div class="nazwa"><span id="połozeniey" [translation]="TranslationEnum.Translation">{{'połozeniey'|translate}}</span></div>
        <div class="wartosc"><input  name="pozy"  [(ngModel)]="pergola.slupy[pergola.edytuj].pozy" (change)="sprawdz(pergola.slupy[pergola.edytuj])"> mm</div>
    </div>
    
    <div class="pole_edycji">
      <div class="nazwa"><span id="czyslup" [translation]="TranslationEnum.Translation">{{'czyslup'|translate}}</span></div>
      <div class="wartosc" *ngIf ="this.pergola.edytuj < 4">
        <input type = "checkbox" [(ngModel)]="pergola.slupy[pergola.edytuj].klient_rodzaj" (change) = "changeCheckPre($event)"> <span id="czy_zmienic_slup" [translation]="TranslationEnum.Translation">{{'czy_zmienic_slup'|translate}}</span>
      </div>
      <div class="wartosc" *ngIf = "pergola.slupy[pergola.edytuj].klient_rodzaj || this.pergola.edytuj >= 4">
        <form [formGroup]="formSlup">
          <mat-form-field>
              <mat-select (selectionChange) = "changeSelectSlup($event)"   [(ngModel)]="pergola.slupy[pergola.edytuj].rodzaj" formControlName="state" width = "auto">
                  <ng-container  *ngFor="let v of ListaSlup; let kk = index;">
                      <mat-option [value]="v"><span id="rodzaj_{{v.toLowerCase()}}" [translation]="TranslationEnum.Translation">{{'rodzaj_'+v.toLowerCase()|translate}}</span></mat-option>
                  </ng-container>
              
              </mat-select>
          </mat-form-field>
      </form>
      </div>
      
    </div>

    <div class="pole_edycji" *ngIf = "pergola.slupy[pergola.edytuj].rodzaj == 'SLUP'">
      <div class="nazwa"><span id="jakastopa" [translation]="TranslationEnum.Translation">{{'jakastopa'|translate}}</span></div>
      <div class="wartosc" *ngIf ="this.pergola.edytuj < 4">
        <input type = "checkbox" [(ngModel)]="pergola.slupy[pergola.edytuj].klient_stopa" (change) = "changeCheckPre($event)"> <span id="czy_zmienic_stope" [translation]="TranslationEnum.Translation">{{'czy_zmienic_stope'|translate}}</span>
      </div>
      <div class="wartosc" *ngIf = "pergola.slupy[pergola.edytuj].klient_stopa || this.pergola.edytuj >= 4">
        <form [formGroup]="form">
          <mat-form-field>
              <mat-select (selectionChange) = "changeSelect($event)"   [(ngModel)]="pergola.slupy[pergola.edytuj].stopa" formControlName="state" width = "auto">
                <ng-container *ngIf = "pergola.slupy[pergola.edytuj].ktory_rog == 0">
                  <ng-container  *ngFor="let v of pergola.ListaStopy; let kk = index;"> 
                    <mat-option [value]="v"><img  src="{{server_url}}//api/ActiveForm/GetIcon?code={{system}}_UkładStopy_{{v}}"> {{pergola.ListaStopyDisplay[kk]}}</mat-option>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf = "pergola.slupy[pergola.edytuj].ktory_rog == 1">
                  <ng-container  *ngFor="let v of pergola.ListaStopy1; let kk = index;"> 
                    <mat-option [value]="v"><img  src="{{server_url}}//api/ActiveForm/GetIcon?code={{system}}_UkładStopy_{{v}}">{{pergola.ListaStopyDisplay1[kk]}}</mat-option>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf = "pergola.slupy[pergola.edytuj].ktory_rog == 2">
                  <ng-container  *ngFor="let v of pergola.ListaStopy2; let kk = index;"> 
                    <mat-option [value]="v"><img  src="{{server_url}}//api/ActiveForm/GetIcon?code={{system}}_UkładStopy_{{v}}"> {{pergola.ListaStopyDisplay2[kk]}}</mat-option>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf = "pergola.slupy[pergola.edytuj].ktory_rog == 3">
                  <ng-container  *ngFor="let v of pergola.ListaStopy3; let kk = index;"> 
                    <mat-option [value]="v"><img  src="{{server_url}}//api/ActiveForm/GetIcon?code={{system}}_UkładStopy_{{v}}"> {{pergola.ListaStopyDisplay3[kk]}}</mat-option>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf = "pergola.slupy[pergola.edytuj].ktory_rog == 4">
                  <ng-container  *ngFor="let v of pergola.ListaStopy4; let kk = index;"> 
                    <mat-option [value]="v"><img  src="{{server_url}}//api/ActiveForm/GetIcon?code={{system}}_UkładStopy_{{v}}"> {{pergola.ListaStopyDisplay4[kk]}}</mat-option>
                  </ng-container>
                </ng-container>
                  -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              
              </mat-select>
          </mat-form-field>
        </form>
      </div>
      
    </div>
    
    <div class="pole_edycji" *ngIf = "pergola.slupy[pergola.edytuj].rodzaj == 'SLUP' && pergola.slupy[pergola.edytuj].ktory_rog != 0 && pergola.slupy[pergola.edytuj].stopa.trim() != '' && pergola.slupy[pergola.edytuj].stopa.trim() != 'BRAK'">
      <div class="nazwa"><span id="czyodwodnienie" [translation]="TranslationEnum.Translation">{{'czyodwodnienie'|translate}}</span></div>
      <div class="wartosc" *ngIf ="this.pergola.edytuj < 4">
        <input type = "checkbox" [(ngModel)]="pergola.slupy[pergola.edytuj].klient_odwodnienie" (change) = "changeCheckPre($event)"> <span id="czy_zmienic_odwodnienie" [translation]="TranslationEnum.Translation">{{'czy_zmienic_odwodnienie'|translate}}</span>
      </div>
      <div class="wartosc" *ngIf = "pergola.slupy[pergola.edytuj].klient_odwodnienie || this.pergola.edytuj >= 4">
        <form [formGroup]="formOdw">
          <mat-form-field>
              <mat-select (selectionChange) = "changeOdw($event)"   [(ngModel)]="pergola.slupy[pergola.edytuj].czy_odwodnienie" formControlName="state" width = "auto">
                  <ng-container  *ngFor="let v of ListaOdwodnienie; let kk = index;">
                      <mat-option [value]="v"><span id="opcja_{{v.toLowerCase()}}" [translation]="TranslationEnum.Translation">{{'opcja_'+v.toLowerCase()|translate}}</span></mat-option>
                  </ng-container>
              
              </mat-select>
          </mat-form-field>
      </form>
      </div>
      
    </div>
    <div class="pole_edycji" *ngIf = "false && pergola.slupy[pergola.edytuj].rodzaj == 'SLUP' && pergola.slupy[pergola.edytuj].stopa.trim() != 'Z' && pergola.slupy[pergola.edytuj].stopa.trim() != '' && pergola.slupy[pergola.edytuj].stopa.trim() != 'BRAK'">
      <div class="nazwa"><span id="czyregulowana" [translation]="TranslationEnum.Translation">{{'czyregulowana'|translate}}</span></div>
      <div class="wartosc" *ngIf ="this.pergola.edytuj < 4">
        <input type = "checkbox" [(ngModel)]="pergola.slupy[pergola.edytuj].klient_stopa_regulowana" (change) = "changeCheckPre($event)"> <span id="czy_zmienic_stopa_reg" [translation]="TranslationEnum.Translation">{{'czy_zmienic_stopa_reg'|translate}}</span>
      </div>
      <div class="wartosc" *ngIf = "pergola.slupy[pergola.edytuj].klient_stopa_regulowana || this.pergola.edytuj >= 4">
        <form [formGroup]="formReg">
          <mat-form-field>
              <mat-select (selectionChange) = "changeReg($event)"   [(ngModel)]="pergola.slupy[pergola.edytuj].czy_stopa_regulowana" formControlName="state" width = "auto">
                  <ng-container  *ngFor="let v of ListaOdwodnienie; let kk = index;">
                      <mat-option [value]="v">{{v}}</mat-option>
                  </ng-container>
              
              </mat-select>
          </mat-form-field>
      </form>
      </div>
    </div>
    <div class="pole_edycji" *ngIf = "pergola.slupRegulowany == 'TAK' && pergola.slupy[pergola.edytuj].rodzaj == 'SLUP' && pergola.slupy[pergola.edytuj].stopa.trim() != 'Z' && pergola.slupy[pergola.edytuj].stopa.trim() != '' && pergola.slupy[pergola.edytuj].stopa.trim() != 'BRAK'">
      <div class="nazwa"><span id="czyregulowana" [translation]="TranslationEnum.Translation"  (change) = "changeCheckPre($event)">{{'czyregulowana'|translate}}</span></div>
      <div class="wartosc" *ngIf ="this.pergola.edytuj < 4">
        <input type = "checkbox" [(ngModel)]="pergola.slupy[pergola.edytuj].klient_stopa_regulowana"> <span id="czy_zmienic_stopa_reg" [translation]="TranslationEnum.Translation">{{'czy_zmienic_stopa_reg'|translate}}</span>
      </div>
      <div class="wartosc" *ngIf = "pergola.slupy[pergola.edytuj].klient_stopa_regulowana || this.pergola.edytuj >= 4">
        <div class="check_pole" *ngIf="pergola.slupy[pergola.edytuj].czy_stopa_regulowana != 'TAK'" (click) = "changeReg('TAK')"><img  src="{{server_url}}//api/ActiveForm/GetIcon?code=Słup_Regulowany"></div>
        <div class="check_pole" *ngIf="pergola.slupy[pergola.edytuj].czy_stopa_regulowana == 'TAK'" (click) = "changeReg('NIE')"><div class="pole_ok"></div><img  src="{{server_url}}//api/ActiveForm/GetIcon?code=Słup_Regulowany"></div>
      </div>
    </div>

    <div class="pole_edycji" *ngIf = "pergola.slupy[pergola.edytuj].rodzaj == 'SLUP'">
      <div class="nazwa"><span id="innawysokosc" [translation]="TranslationEnum.Translation">{{'innawysokosc'|translate}}</span> ({{pergola.slupy[pergola.edytuj].minwys}}-{{pergola.slupy[pergola.edytuj].maxwys}})</div>
      <div class="wartosc">
        <input type = "checkbox" [(ngModel)]="pergola.slupy[pergola.edytuj].czyinnawys" (change) = "changeCheck($event)">
        <span id="czy_inna_wysokosc_slupa" [translation]="TranslationEnum.Translation">{{'czy_inna_wysokosc_slupa'|translate}}</span>
      </div>
      <div class="wartosc" *ngIf = "pergola.slupy[pergola.edytuj].czyinnawys"><input  name="innawys"  [(ngModel)]="pergola.slupy[pergola.edytuj].innawysokosc" > mm</div>
    </div>

    
</ng-container>

<div mat-dialog-actions>
    <div class="w-100">
      <button mat-flat-button id="akceptuj" class="btn-sm float-right" [translation]="TranslationEnum.Translation"
        color="primary" [mat-dialog-close]="true">
        {{'ok'|translate}}
      </button>
    </div>
  </div>