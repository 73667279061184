import { HttpClient, HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';
import { Uwagi } from './uwagi';
import { deserialize, serialize } from 'class-transformer';

export class ModuleContainer {
  public dane = [];
  constructor(Matrix) {
    this.dane = Matrix;
  }

}
export class Module {
  public Min;
  public Max;
  public Value;
  public Value_slider;
  public step = 5;
  public Slider = false;
  public KeyName;
  public uwagi: Uwagi;
  public Typ;
  public module_array = [];
  public wybrany = false;
  public matrix = [];
  public ilosc= 0;
  public MaxModules: number;
    constructor(private id, uwagi, KeyName, Typ, private tresci, private wartosc, private numer_modulu) {
      var t = Typ.split(':');
      this.uwagi = uwagi;
      this.KeyName = KeyName;
      if(t.length > 0){
        this.Typ = t[0];
      }
      if(t.length > 1){
        this.MaxModules = t[1];
      }
      
      try {
        let tmp;
        if (this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName) !== null) {
          wartosc = this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName);
        }
        tmp = deserialize(ModuleContainer, wartosc);
        this.wartosc = tmp.dane;
      } catch (e) {
        this.wartosc = [];
      }


      for (let i = 0; i < 10; i++) {
        const tmp = [];
        for (let j = 0; j < 10; j++) {
          tmp.push(false);
        }
        this.module_array.push(tmp);
      }

      for (let i = 0; i < this.wartosc.length; i++) {
        for (let j = 0; j < this.wartosc[i].length; j++) {
          this.module_array[j][i] = this.wartosc[i][j];
        }
      }

      this.aktualizujMatrix();
      //if (this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName) !== null) {
        // this.ustaw(this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName));
        //this.Value = parseInt(this.tresci.zamowienie.parametry_wybrane.get(this.id, this.KeyName), 10);
      //}

    }
    public aktualizujMatrix() {
      this.matrix = [];
      this.wybrany = false;
      for (let i = 0; i < this.getY() && i < 10; i++) {
        const tmp = [];
        for (let j = 0; j < this.getX() && j < 10; j++) {
          tmp.push(this.module_array[j][i]);
          if (this.module_array[j][i]) {
            this.wybrany = true;
          }
        }
        this.matrix.push(tmp);
      }



    }
    public poza() {
    }

    public getActual() {
      this.tresci.zamowienie.parametry_wybrane.add(this.id, this.KeyName, serialize(new ModuleContainer(this.matrix)));
      this.zapisz_potrzebne();
      return serialize(new ModuleContainer(this.matrix));
    }

    public zapisz_potrzebne() {
      let licznik = 0;
      for (let i = 0; i < this.matrix.length; i++) {
        for (let j = 0; j < this.matrix[i].length; j++) {
          if (this.matrix[i][j]) {
            this.tresci.zamowienie.parametry_wybrane.add(-1, 'Modul_rodzaj_' + this.numer_modulu + '_' + licznik, this.getRodzaj(i, j) );
            licznik++;
          }
        }
      }
    }
    public getValue() {
      let licznik = 0;
      let tmp = '';
      for (let i = 0; i < this.matrix.length; i++) {
        for (let j = 0; j < this.matrix[i].length; j++) {
          if (this.matrix[i][j]) {
            if(licznik == 0){
              tmp += i+':'+j+':'+this.getRodzaj(i,j);
            } else {
              tmp += ',' + i+':'+j+':'+this.getRodzaj(i,j);
            }
            
            licznik++;
          }
        }
      }
      return tmp;
    }
    public getDisplay() {
      let licznik = 0;
      let tmp = '';
      for (let i = 0; i < this.matrix.length; i++) {
        for (let j = 0; j < this.matrix[i].length; j++) {
          if (this.matrix[i][j]) {
            tmp += 'M' + (licznik + 1) + '=' + this.getRodzaj(i, j) + ', ';
            licznik++;
          }
        }
      }
      return tmp;
    }
    public czySasiad(y, x){
      let gora = false;
      let prawo = false;
      let dol = false;
      let lewo = false;
      if (x > 0 && this.matrix[y][x - 1]) {
        lewo = true;
      }
      if (y > 0 && this.matrix[y - 1][x]) {
        gora = true;
      }
      if (this.matrix[y][x + 1]) {
        prawo = true;
      }
      if (this.matrix.length > y+1 && this.matrix[y + 1][x]) {
        dol = true;
      }
      if(this.Typ === '1R' && y == 1 && x == 1){
        return false;
      }
      if(this.ilosc == 0 || gora || prawo || dol || lewo){
        return true;
      } else {
        return false;
      }
    }
    public getRodzaj(y, x) {
      let gora = false;
      let prawo = false;
      let dol = false;
      let lewo = false;
      if (x > 0 && this.matrix[y][x - 1]) {
        lewo = true;
      }
      if (y > 0 && this.matrix[y - 1][x]) {
        gora = true;
      }
      if (this.matrix[y][x + 1]) {
        prawo = true;
      }
      if (this.matrix.length > y+1 && this.matrix[y + 1][x]) {
        dol = true;
      }
      if (!gora && !prawo && !dol && !lewo) {
        return '11';
      } else if (!gora && !prawo && dol && !lewo) {
        return 'G11';
      } else if (gora && !prawo && dol && !lewo) {
        return 'S11';
      } else if (gora && !prawo && !dol && !lewo) {
        return 'D11';
      } else if (!gora && prawo && !dol && !lewo) {
        return '1P';
      } else if (!gora && prawo && !dol && lewo) {
        return 'PP';
      } else if (!gora && !prawo && !dol && lewo) {
        return 'P1';
      } else if (!gora && prawo && dol && !lewo) {
        return 'G1P';
      } else if (!gora && prawo && dol && lewo) {
        return 'GPP';
      } else if (!gora && !prawo && dol && lewo) {
        return 'GP1';
      } else if (gora && prawo && dol && !lewo) {
        return 'S1P';
      } else if (gora && prawo && dol && lewo) {
        return 'SPP';
      } else if (gora && !prawo && dol && lewo) {
        return 'SP1';
      } else if (gora && prawo && !dol && !lewo) {
        return 'D1P';
      } else if (gora && prawo && !dol && lewo) {
        return 'DPP';
      } else if (gora && !prawo && !dol && lewo) {
        return 'DP1';
      }
      return 'X';
    }
    public szukaj(szukana_poz:Pozycja[], aktualna_poz:Pozycja, strona_wejscia,  odwiedzone_adresy:Pozycja[]){
      var lewo = 0;
      var gora = 0;
      var prawo = 0;
      var dol = 0;
      var punkt_lewo:Pozycja = new Pozycja();
      var punkt_gora:Pozycja = new Pozycja();
      var punkt_prawo:Pozycja = new Pozycja();
      var punkt_dol:Pozycja = new Pozycja();
      // console.log("aktualna poz", aktualna_poz, strona_wejscia);
      if(aktualna_poz.x > 0 && strona_wejscia != "lewo"){
        punkt_lewo.x = aktualna_poz.x - 1;
        punkt_lewo.y = aktualna_poz.y;
        if(this.module_array[aktualna_poz.x - 1][aktualna_poz.y]){
          if(odwiedzone_adresy.find(v => v.x == aktualna_poz.x - 1 && v.y == aktualna_poz.y)){
            // console.log("był adres lewo", aktualna_poz);
          } else {
            lewo = 1;
          }
        }
      }
      if(aktualna_poz.y > 0 && strona_wejscia != "gora"){
        punkt_gora.x = aktualna_poz.x;
        punkt_gora.y = aktualna_poz.y - 1;
        if(this.module_array[aktualna_poz.x][aktualna_poz.y - 1]){
          if(odwiedzone_adresy.find(v => v.x == aktualna_poz.x && v.y == aktualna_poz.y - 1)){
            // console.log("był adres gora", aktualna_poz);
          } else {
            gora = 1;
          }
        }
      }
      if(aktualna_poz.y < this.module_array[aktualna_poz.x].length && strona_wejscia != "dol" && aktualna_poz.y < 10){
        punkt_dol.x = aktualna_poz.x;
        punkt_dol.y = aktualna_poz.y + 1;
        if(this.module_array[aktualna_poz.x][aktualna_poz.y + 1]){
          if(odwiedzone_adresy.find(v => v.x == aktualna_poz.x && v.y == aktualna_poz.y + 1)){
            // console.log("był adres dol", aktualna_poz);
          } else {
            dol = 1;
          }
        }
      }
      if(aktualna_poz.x < this.module_array.length && strona_wejscia != "prawo" && aktualna_poz.x < 10){
        punkt_prawo.x = aktualna_poz.x + 1;
        punkt_prawo.y = aktualna_poz.y;
        if(this.module_array[aktualna_poz.x + 1][aktualna_poz.y]){
          if(odwiedzone_adresy.find(v => v.x == aktualna_poz.x + 1 && v.y == aktualna_poz.y)){
            // console.log("był adres prawo", aktualna_poz);
          } else {
            prawo = 1;
          }
        }
      }
      if(lewo+prawo+gora+dol == 0){
        // console.log("brak połączeń");
        return false;
      } else {

        // console.log("akt_poz", aktualna_poz);
        odwiedzone_adresy.push(aktualna_poz);
        // console.log("odw", odwiedzone_adresy);
        
        if(lewo == 1 && szukana_poz.find(v => v.x == punkt_lewo.x && v.y == punkt_lewo.y)){
          var p = szukana_poz.find(v => v.x == punkt_lewo.x && v.y == punkt_lewo.y);
          // console.log("znalazł pole po lewej", p, szukana_poz, szukana_poz.indexOf(p), szukana_poz.length);
          if(szukana_poz.length == 1){
            return true;
          }
          szukana_poz.splice(szukana_poz.indexOf(p), 1);
        }
        if(gora == 1 && szukana_poz.find(v => v.x == punkt_gora.x && v.y == punkt_gora.y)){
          var p = szukana_poz.find(v => v.x == punkt_gora.x && v.y == punkt_gora.y);
          // console.log("znalazł pole po gora", p, szukana_poz, szukana_poz.indexOf(p)), szukana_poz.length;
          if(szukana_poz.length == 1){
            return true;
          }
          szukana_poz.splice(szukana_poz.indexOf(p), 1);
        }
        if(dol == 1 && szukana_poz.find(v => v.x == punkt_dol.x && v.y == punkt_dol.y)){
          var p = szukana_poz.find(v => v.x == punkt_dol.x && v.y == punkt_dol.y);
          // console.log("znalazł pole po dol", p, szukana_poz, szukana_poz.indexOf(p), szukana_poz.length);
          if(szukana_poz.length == 1){
            return true;
          }
          szukana_poz.splice(szukana_poz.indexOf(p), 1);
          var x = szukana_poz;
        }
        if(prawo == 1 && szukana_poz.find(v => v.x == punkt_prawo.x && v.y == punkt_prawo.y)){
          var p =  szukana_poz.find(v => v.x == punkt_prawo.x && v.y == punkt_prawo.y);
          // console.log("znalazł pole po prawo", p, szukana_poz, szukana_poz.indexOf(p), szukana_poz.length);
          if(szukana_poz.length == 1){
            return true;
          }
          szukana_poz.splice(szukana_poz.indexOf(p), 1);
        }
        if(szukana_poz.length == 0){
          // console.log("znalazł wszystkie");
          return true;
        } else {
          // console.log("nie znalazł wszystkie", szukana_poz.length, szukana_poz);
        }
        /*
        if((lewo == 1 && szukana_poz.find(v => v.x == punkt_lewo.x && v.y == punkt_lewo.y + 1))  || 
        (gora == 1 && szukana_poz.find(v => v.x == punkt_gora.x && v.y == punkt_gora.y + 1))  || 
        (dol == 1 && szukana_poz.find(v => v.x == punkt_dol.x && v.y == punkt_dol.y + 1))  || 
        (prawo == 1 && szukana_poz.find(v => v.x == punkt_prawo.x && v.y == punkt_prawo.y + 1)) ){
          // console.log("znalazł obieg!");
          return true;
        } else 
         */
      if((lewo == 1 && this.szukaj(szukana_poz, punkt_lewo, "prawo",  odwiedzone_adresy)) || 
        (gora == 1 && this.szukaj(szukana_poz, punkt_gora, "dol", odwiedzone_adresy)) || 
        (dol == 1 && this.szukaj(szukana_poz, punkt_dol, "gora", odwiedzone_adresy)) || 
        (prawo == 1 && this.szukaj(szukana_poz, punkt_prawo, "lewo",odwiedzone_adresy))
      ){
        
          return true;
        } else  {
          return false;
        }
      }

      
    }
    public szukaj1(aktualna_poz:Pozycja){
      var lewo = 0;
      var gora = 0;
      var prawo = 0;
      var dol = 0;
      var punkt_lewo:Pozycja = new Pozycja();
      var punkt_gora:Pozycja = new Pozycja();
      var punkt_prawo:Pozycja = new Pozycja();
      var punkt_dol:Pozycja = new Pozycja();
      if(aktualna_poz.x > 0){
        punkt_lewo.x = aktualna_poz.x - 1;
        punkt_lewo.y = aktualna_poz.y;
        
        if(this.module_array[aktualna_poz.x - 1][aktualna_poz.y]){
          lewo = 1;
        }
      }
      if(aktualna_poz.y > 0){
        punkt_gora.x = aktualna_poz.x;
        punkt_gora.y = aktualna_poz.y - 1;
        if(this.module_array[aktualna_poz.x][aktualna_poz.y - 1]){
          gora = 1;
        }
      }
      if(aktualna_poz.y < this.module_array[aktualna_poz.x].length - 1){
        punkt_dol.x = aktualna_poz.x;
        punkt_dol.y = aktualna_poz.y + 1;
        if(this.module_array[aktualna_poz.x][aktualna_poz.y + 1]){
          dol = 1;
        }
      }
      if(aktualna_poz.x < this.module_array.length - 1){
        punkt_prawo.x = aktualna_poz.x + 1;
        punkt_prawo.y = aktualna_poz.y;
        if(this.module_array[aktualna_poz.x + 1][aktualna_poz.y]){
          prawo = 1;
        }
      }
      if(lewo+prawo+dol+gora == 1){
        // console.log("był 1 więc ok");
        return true;
      } else {
        // console.log("str", lewo, gora, dol, prawo);



        var odwiedzone:Pozycja[] = [];
        odwiedzone.push(aktualna_poz);
        if(lewo == 1)
        {
          var szukane_poz:Pozycja[] = [];
          if(prawo == 1){
            szukane_poz.push(punkt_prawo);
          }
          if(gora == 1){
            szukane_poz.push(punkt_gora);
          }
          if(dol == 1){
            szukane_poz.push(punkt_dol);
          }
          if(!this.szukaj(szukane_poz, punkt_lewo, "prawo",  odwiedzone)){
            // console.log("brak drogi lewo pierwsze");
            return false;
          } else {
            return true;
          }
        } else if (gora == 1) {
          var szukane_poz:Pozycja[] = [];
          if(prawo == 1){
            szukane_poz.push(punkt_prawo);
          }
          if(dol == 1){
            szukane_poz.push(punkt_dol);
          }
          if(!this.szukaj(szukane_poz, punkt_gora, "dol",odwiedzone)){
            // console.log("brak drogi gora pierwsze");
            return false;
          } else {
            return true;
          }
        } else if (dol == 1) {
          var szukane_poz:Pozycja[] = [];
          if(prawo == 1){
            szukane_poz.push(punkt_prawo);
          }
          if(!this.szukaj(szukane_poz, punkt_dol, "gora",odwiedzone)){
            // console.log("brak drogi dol pierwsze");
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }

      }

    }
    public set(x, y) {
      
      
      var typ = '';
      //if(this.module_array[x][y]){
      //  typ = this.getRodzaj(y,x);
      //} 
      // console.log("typ", typ);
      if(this.module_array[x][y]){
        
          var szukane = new Pozycja();
          szukane.x = x;
          szukane.y = y;
          var było_polaczenie = this.szukaj1(szukane);
          // console.log("bylo_placzenie", było_polaczenie);
          if(było_polaczenie){
            this.module_array[x][y] = !this.module_array[x][y];
            if(this.module_array[x][y]){
              this.ilosc++ ;
            } else {
              this.ilosc--;
            
            }
            this.aktualizujMatrix();
          } else {
            return false;
          }
      } else {
        if(this.ilosc >= this.MaxModules){
          return false;
        } else {
          this.module_array[x][y] = !this.module_array[x][y];
          if(this.module_array[x][y]){
            this.ilosc++ ;
          } else {
            this.ilosc--;
          
          }
          
          this.aktualizujMatrix();
        }
        
      }
      
      return true;
      // this.tresci.zamowienie.zapisz_Parametry();
    }

    
    public getName(x, y) {
      let licz = 0;
      for (let i = 0; i < 10; i++) {
        for (let j = 0; j < 10; j++) {
          if (this.module_array[j][i]) {
            licz++;
          }
          if (x === j && y === i) {
            return licz;
          }
        }
      }
    }
    public getX() {
      if (this.Typ === '1V') {
        return 1;
      } else if (this.Typ === '2V') {
        return 2;
      } else if (this.Typ === '1R' && this.module_array[0][1]) {
        return 1;
      } else {
        let max = 2;
        for (let i = 0; i < 10; i++) {
          for (let j = 0; j < 10; j++) {
            if (this.module_array[j][i] && j + 2 > max) {
              max = j + 2;
            }
          }
        }
        return max;
      }
    }
    public getY() {
      if (this.Typ === '1H') {
        return 1;
      } else if (this.Typ === '2H') {
        return 2;
      } else if (this.Typ === '1R' && this.module_array[1][0]) {
        return 1;
      } else {
        let max = 2;
        for (let i = 0; i < 10; i++) {
          for (let j = 0; j < 10; j++) {
            if (this.module_array[j][i] && i + 2 > max) {
              max = i + 2;
            }
          }
        }
        return max;
      }
    }

}
export class Pozycja {
  public x:number
  public y:number;
} 

