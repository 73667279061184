
import { GrupaKrokow } from "./GrupaKrokow";
import { Supervisor } from "./Supervisor";
import { Slup } from "./Slup";
import { Control } from "./Control";
import { Parameter } from "src/app/shared/models/form-model/models/form-controlls.model";
import { Wneka } from "./Wneka";

export class Pergola {

    szerokosc = 4000;
    glebokosc = 5000;
    szerokosc_slupa = 150;
    glebokosc_slupa = 85;

    minimalna_odleglosc = 800;

    szerokosc_w_px = 510;

    slupy:Slup[] = [];

    edytuj = -1;
    skala = 0.2;

    random = Math.ceil(Math.random() * 9999999999);

    scianaA = false;
    scianaB = false;
    scianaC = false;
    scianaD = false;

    pergolaA = false;
    pergolaB = false;
    pergolaC = false;
    pergolaD = false;
    
    blokadaA = false;
    blokadaB = false;
    blokadaC = false;
    blokadaD = false;

    IloscSlupow = 0;
    ListaStopy = [];
    ListaStopyDisplay = [];
    ListaStopy1 = [];
    ListaStopyDisplay1 = [];
    ListaStopy2 = [];
    ListaStopyDisplay2 = [];
    ListaStopy3 = [];
    ListaStopyDisplay3 = [];
    ListaStopy4 = [];
    ListaStopyDisplay4 = [];
    WysokoscSlup = [];
    Modul = '11';
    OpcjeZaawansowane = "NIE";
    system = "";
    slupRegulowany = "TAK";
    pergolaDodatki = "TAK";

    Wneki:Wneka[] = [];
    Wneki_wybrane:Wneka[] = [];

    constructor(){
        
        this.JakiWymiar();
    }
    getSkala(){
      // this.szerokosc_w_px / this.szerokosc;
      /*if(this.glebokosc > 5000){
        return 0.15;
      } else {
        return 0.2;
      } */
      return this.skala; 
      
    }
    getSerokosc_w_px(){
      return this.szerokosc_w_px * this.getSkala() / (this.szerokosc_w_px / this.szerokosc);
    }
    ustawSkale(_skala){
      this.skala = _skala;
      this.slupy.forEach(s => {
        
        s.skala = _skala;
      });
      
    }
    getPar(value, name, module, position){
      var p = new Parameter();
        p.Disabled = false;
        p.DisplayValue = value;
        p.DisplayValueCs = value;
        p.DisplayValueDe = value;
        p.DisplayValueEn = value;
        p.DisplayValueFr = value;
        p.DisplayValueIt = value;
        p.DisplayValuePl = value;
        p.Key = name;
        p.Module = module;
        p.Position = position;
        p.Type = 3;
        p.Value = value;
        p.IsPrint = false;
        return p;
    }
    getValues(module, position){
      var par:Parameter[] = [];
      var i = 0;
      this.slupy.forEach(s => {
        if(i< 4){
          par.push(this.getPar(s.rodzaj, "Slup" + (i+1), module, position));
          if(s.rodzaj =='SLUP'){
            par.push(this.getPar(s.czy_odwodnienie, "SlupOdwodnienie" + (i+1), module, position));
            if(this.slupRegulowany == "TAK"){
              par.push(this.getPar(s.czy_stopa_regulowana, "SlupStopaReg" + (i+1), module, position));
            }
            
            par.push(this.getPar(s.stopa, "SlupStopa" + (i+1), module, position));
            if(s.czyinnawys){
              par.push(this.getPar(s.innawysokosc, "SlupWysokosc" + (i+1), module, position));
            }
          }
          
        } else {
          par.push(this.getPar(s.rodzaj, "SlupDod" + (i-3), module, position));
          if(s.rodzaj =='SLUP'){
            // par.push(this.getPar(s.czy_odwodnienie, "SlupDodOdwodnienie" + (i+1), module, position));
            if(this.slupRegulowany == "TAK"){
              par.push(this.getPar(s.czy_stopa_regulowana, "SlupDodStopaReg" + (i-3), module, position));
            }
            
            par.push(this.getPar(s.stopa, "SlupDodStopa" + (i-3), module, position));
            if(s.czyinnawys){
              par.push(this.getPar(s.innawysokosc, "SlupDodWysokosc" + (i-3), module, position));
            }
            if(s.jakaStrona(this) == 1 || s.jakaStrona(this) == 3){
              par.push(this.getPar(s.pozy, "SlupDodPozycja" + (i-3), module, position));
            } else {
              par.push(this.getPar(s.pozx, "SlupDodPozycja" + (i-3), module, position));
            }
            if(s.jakaStrona(this) == 1){
              par.push(this.getPar('D', "SlupDodStrona" + (i-3), module, position));
            }
            if(s.jakaStrona(this) == 2){
              par.push(this.getPar('A', "SlupDodStrona" + (i-3), module, position));
            }
            if(s.jakaStrona(this) == 3){
              par.push(this.getPar('B', "SlupDodStrona" + (i-3), module, position));
            }
            if(s.jakaStrona(this) == 4){
              par.push(this.getPar('C', "SlupDodStrona" + (i-3), module, position));
            }
            
          }
        }
        
        i++;
      });
      return par;
    }
    getValue(){
      var val = "";
      var i = 0;
      this.slupy.forEach(s => {
        if(i != 0){
          val += ';';
        }
        i++;
          val += s.czy_odwodnienie + ',';
          val += s.czy_stopa_regulowana + ',';
          val += s.czy_uchwyt + ',';
          val += s.czy_widoczny + ',';
          val += s.pozx + ',';
          val += s.pozy + ',';
          val += s.ktory_rog + ',';
          val += s.rodzaj + ',';
          val += s.stopa + ',';
          val += s.innawysokosc + ',';
          val += s.czyinnawys + ',';
          val += s.klient_odwodnienie + ',';
          val += s.klient_rodzaj + ',';
          val += s.klient_stopa + ',';
          val += s.klient_stopa_regulowana + '';
        
      });
      return val;
    }
    czyPoprawne(){
      var poprawne = true;
      this.slupy.forEach(s => {
        if(s.blad || s.blad_nachodzi || s.bladWysokosc || s.bladBlokada){
          poprawne = false;
        }
        if(s.stopa == '' && s.rodzaj == 'SLUP'){
          poprawne = false;
        }
        if(!s.czy_mozliwy_uchwyt && s.rodzaj == 'UCHWYT'){
          poprawne = false;
        }
      });
      // console.log("poprawnosc pergola", poprawne);
      if(this.OpcjeZaawansowane == "TAK"){
        return true;
      }

      return poprawne;
    }
    ustawWneki(){
      var sciana = "A";
      var x = 0;
      var y = 0;
      var koniec = this.szerokosc;
      this.Wneki = [];
      var l = 0;
      while(x < this.szerokosc && l < 10){
        l++;
        this.slupy.forEach(s => {
          if(x >= s.pozx && x < s.pozx + s.width && s.pozy == y  && s.rodzaj == 'SLUP'){
              x = s.pozx + s.width;
          }
        });
        this.slupy.forEach(s => {
          if(x < s.pozx && s.pozy == y  && s.rodzaj == 'SLUP' && s.pozx < koniec){
            koniec = s.pozx;
          }
        });
        var w = new Wneka();
        w.sciana = sciana;
        w.poz = x;
        w.dlugosc = koniec - x;
        this.Wneki.push(w);
        x = koniec + this.szerokosc_slupa;
        koniec = this.szerokosc;

      }
      x = 0;
      y = this.glebokosc - this.glebokosc_slupa;
      sciana = "C";
      koniec = this.szerokosc;
      l = 0;
      while(x < this.szerokosc && l < 10){
        l++;
        this.slupy.forEach(s => {
          if(x >= s.pozx && x < s.pozx + s.width && s.pozy == y  && s.rodzaj == 'SLUP'){
              x = s.pozx + s.width;
          }
        });
        this.slupy.forEach(s => {
          if(x < s.pozx && s.pozy == y  && s.rodzaj == 'SLUP' && s.pozx < koniec){
            koniec = s.pozx;
          }
        });
        var w = new Wneka();
        w.sciana = sciana;
        w.poz = x;
        w.dlugosc = koniec - x;
        this.Wneki.push(w);
        x = koniec + this.szerokosc_slupa;
        koniec = this.szerokosc;

      }
      x = this.szerokosc - this.szerokosc_slupa;
      y = 0;
      sciana = "B";
      koniec = this.glebokosc;
      l = 0;
      while(y < this.glebokosc && l < 10){
        l++;
        this.slupy.forEach(s => {
          if(y >= s.pozy && y < s.pozy + s.height && s.pozx == x  && s.rodzaj == 'SLUP'){
              y = s.pozy + s.height;
          }
        });
        this.slupy.forEach(s => {
          if(y < s.pozy && s.pozx == x  && s.rodzaj == 'SLUP' && s.pozy < koniec){
            koniec = s.pozy;
          }
        });
        var w = new Wneka();
        w.sciana = sciana;
        w.poz = y;
        w.dlugosc = koniec - y;
        this.Wneki.push(w);
        y = koniec + this.glebokosc_slupa;
        koniec = this.glebokosc;

      }
      x = 0;
      y = 0;
      sciana = "D";
      koniec = this.glebokosc;
      l = 0;
      while(y < this.glebokosc && l < 10){
        l++;
        this.slupy.forEach(s => {
          if(y >= s.pozy && y < s.pozy + s.height && s.pozx == x  && s.rodzaj == 'SLUP'){
              y = s.pozy + s.height;
          }
        });
        this.slupy.forEach(s => {
          if(y < s.pozy && s.pozx == x  && s.rodzaj == 'SLUP' && s.pozy < koniec){
            koniec = s.pozy;
          }
        });
        var w = new Wneka();
        w.sciana = sciana;
        w.poz = y;
        w.dlugosc = koniec - y;
        this.Wneki.push(w);
        y = koniec + this.glebokosc_slupa;
        koniec = this.glebokosc;

      }
      this.ustaw_pxWnek();
      // console.log("wneki", this.Wneki, x, koniec);
    }
    ustaw_pxWnek(){

      this.Wneki_wybrane.forEach(ww => {
        ww.checked = false;
      });

      this.Wneki.forEach(w => {
        if(w.sciana == "A"){
          w.pozx = w.poz * this.getSkala();
          w.pozy = 0;
          w.szerokosc_w_px = w.dlugosc * this.getSkala();
          w.wysokosc_w_px = this.glebokosc_slupa * this.getSkala();
        } else if (w.sciana == "B"){
          w.pozx = (this.szerokosc - this.szerokosc_slupa) * this.getSkala();
          w.pozy = w.poz * this.getSkala();
          w.wysokosc_w_px = w.dlugosc * this.getSkala();
          w.szerokosc_w_px = this.szerokosc_slupa * this.getSkala();
        }  else if (w.sciana == "C"){
          w.pozx = w.poz * this.getSkala();
          w.pozy = (this.glebokosc - this.glebokosc_slupa) * this.getSkala();
          w.szerokosc_w_px = w.dlugosc * this.getSkala();
          w.wysokosc_w_px = this.glebokosc_slupa * this.getSkala();
        }  else if (w.sciana == "D"){
          w.pozx = 0;
          w.pozy = w.poz * this.getSkala();
          w.wysokosc_w_px = w.dlugosc * this.getSkala();
          w.szerokosc_w_px = this.szerokosc_slupa * this.getSkala();
        } 
        for(var i = 0; i < this.Wneki_wybrane.length;i++){
          if(w.sciana == this.Wneki_wybrane[i].sciana && w.pozx == this.Wneki_wybrane[i].pozx && w.pozy == this.Wneki_wybrane[i].pozy){
            w.checked = true;
            w.numer = i + 1;
            this.Wneki_wybrane[i] = w;
            this.Wneki_wybrane[i].checked = true;
            console.log(this.Wneki_wybrane[i], this.Wneki_wybrane[i].checked);
            console.log("ustawiam id ok ", i);
          }
        }
        for(var i = 0; i < this.Wneki_wybrane.length;i++){
          if(w.sciana == "A" || w.sciana == "C"){
            if(w.sciana == this.Wneki_wybrane[i].sciana && w.poz + w.dlugosc == this.Wneki_wybrane[i].poz + this.Wneki_wybrane[i].dlugosc && w.pozy == this.Wneki_wybrane[i].pozy){
              if(!w.checked){
                w.checked = true;
                w.numer = i + 1;
                this.Wneki_wybrane[i] = w;
                this.Wneki_wybrane[i].checked = true;
                console.log("ustawiam id", i);
              }
            }
          } else {
            if(w.sciana == this.Wneki_wybrane[i].sciana && w.pozx  == this.Wneki_wybrane[i].pozx  && w.poz + w.dlugosc == this.Wneki_wybrane[i].poz + this.Wneki_wybrane[i].dlugosc){
              if(!w.checked){
                w.checked = true;
                w.numer = i + 1;
                this.Wneki_wybrane[i] = w;
                this.Wneki_wybrane[i].checked = true;
                console.log("ustawiam id --", i);
              }
            }
          }
        }


      });
      var byl = false;
      for(var i = this.Wneki_wybrane.length - 1; i >=0;i--){
        if(!this.Wneki_wybrane[i].checked){
          this.Wneki_wybrane.splice(i, 1);
          byl = true;
        }
      }
      if(byl){
        this.ustaw_pxWnek();
      }

    }
    ustawSkaleMinus(){
      
      if(this.getWidth() > 200){
        this.skala = this.skala - 0.01;
        /*this.slupy.forEach(s => {
          s.ustawSkale(this.skala)
        }); */
        for(var i = 0; i < this.slupy.length; i++) {
          this.slupy[i].ustawSkale(this.skala)
        }
      }
      
    }
    ustawSkalePlus(){
      if(this.getWidth() < 905){
        this.skala = this.skala + 0.01;
        for(var i = 0; i < this.slupy.length; i++) {
          this.slupy[i].ustawSkale(this.skala)
        }
      }
      
      /*this.slupy.forEach(s => {
        s.ustawSkale(this.skala)
      });*/
    }
    setScaleStd(){
      this.ustawSkale(this.szerokosc_w_px / this.szerokosc);
      for(var i = 0; i < this.slupy.length; i++) {
        this.slupy[i].ustawSkale(this.skala)
      }
    }
    Init(control: Control){
        
      control.Parameters.forEach(param => {
        if(param.Key == 'szerokosc'){
          if(!Number.isNaN(Number.parseInt(param.Value))){
            this.szerokosc = Number.parseInt(param.Value) * 1;
          }
          
        } else if(param.Key == 'glebokosc'){
          if(!Number.isNaN(Number.parseInt(param.Value))){
            this.glebokosc = Number.parseInt(param.Value) * 1;
          }
          
        } else if(param.Key == 'szerkosc_slupa'){
          if(!Number.isNaN(Number.parseInt(param.Value))){
            this.szerokosc_slupa = Number.parseInt(param.Value) * 1;
          }
          
        } else if(param.Key == 'glebokosc_slupa'){
          if(!Number.isNaN(Number.parseInt(param.Value))){
            this.glebokosc_slupa = Number.parseInt(param.Value) * 1;
          }
          
        } else if(param.Key == 'minimalna_odleglosc'){
          if(!Number.isNaN(Number.parseInt(param.Value))){
            this.minimalna_odleglosc = Number.parseInt(param.Value) * 1;
          }
          
        } 
      });

      this.ustawSkale(this.szerokosc_w_px / this.szerokosc);
      var skala = this.getSkala();

     

            // console.log("pergola", this.control);
           
            console.log("OP", control.OtherParams);
            control.OtherParams.forEach(param => {
              if(param.Key == 'ScianaA'){
                if(param.Value == "TAK"){
                  this.scianaA = true;
                } else {
                  this.scianaA = false;
                }
              } else if(param.Key == 'ScianaB'){
                if(param.Value == "TAK"){
                  this.scianaB = true;
                } else {
                  this.scianaB = false;
                }
              } else if(param.Key == 'ScianaC'){
                if(param.Value == "TAK"){
                  this.scianaC = true;
                } else {
                  this.scianaC = false;
                }
              } else if(param.Key == 'ScianaD'){
                if(param.Value == "TAK"){
                  this.scianaD = true;
                } else {
                  this.scianaD = false;
                }
              } else if(param.Key == 'IloscSlupow'){
                this.IloscSlupow = Number.parseInt(param.Value) * 1;
              } else if(param.Key == 'WysokoscSlup'){
                this.WysokoscSlup = param.Value.split("|");
              } else if(param.Key == 'ListaStopy'){
                // console.log("params", control.OtherParams);
                this.ListaStopy = param.Value.split("|");
                this.ListaStopyDisplay = param.DisplayValue.split("|");
              }  else if(param.Key == 'ListaStopy1'){
                // console.log("params", control.OtherParams);
                this.ListaStopy1 = param.Value.split("|");
                this.ListaStopyDisplay1 = param.DisplayValue.split("|");
              }  else if(param.Key == 'ListaStopy2'){
                // console.log("params", control.OtherParams);
                this.ListaStopy2 = param.Value.split("|");
                this.ListaStopyDisplay2 = param.DisplayValue.split("|");
              }  else if(param.Key == 'ListaStopy3'){
                // console.log("params", control.OtherParams);
                this.ListaStopy3 = param.Value.split("|");
                this.ListaStopyDisplay3 = param.DisplayValue.split("|");
              }  else if(param.Key == 'ListaStopy4'){
                // console.log("params", control.OtherParams);
                this.ListaStopy4 = param.Value.split("|");
                this.ListaStopyDisplay4 = param.DisplayValue.split("|");
              }   else if(param.Key == 'Moduł'){
                this.Modul = param.Value;
              }  else if(param.Key == 'OpcjeZaawansowane'){
                this.OpcjeZaawansowane = param.Value;
              } else if(param.Key == 'PergolaTyl'){
                if(param.Value == "TAK"){
                  this.pergolaA = true;
                } else {
                  this.pergolaA = false;
                }
              } else if(param.Key == 'PergolaPrawa'){
                if(param.Value == "TAK"){
                  this.pergolaB = true;
                } else {
                  this.pergolaB = false;
                }
              } else if(param.Key == 'PergolaPrzod'){
                if(param.Value == "TAK"){
                  this.pergolaC = true;
                } else {
                  this.pergolaC = false;
                }
              } else if(param.Key == 'PergolaLewa'){
                if(param.Value == "TAK"){
                  this.pergolaD = true;
                } else {
                  this.pergolaD = false;
                }
              } else if(param.Key == 'BlokadaA'){
                if(param.Value == "TAK"){
                  this.blokadaA = true;
                } else {
                  this.blokadaA = false;
                }
              } else if(param.Key == 'BlokadaB'){
                if(param.Value == "TAK"){
                  this.blokadaB = true;
                } else {
                  this.blokadaB = false;
                }
              } else if(param.Key == 'BlokadaC'){
                if(param.Value == "TAK"){
                  this.blokadaC = true;
                } else {
                  this.blokadaC = false;
                }
              } else if(param.Key == 'BlokadaD'){
                if(param.Value == "TAK"){
                  this.blokadaD = true;
                } else {
                  this.blokadaD = false;
                }
              } else if(param.Key == 'System'){
                this.system = param.Value;
              } else if(param.Key == 'SlupRegulowany'){
                this.slupRegulowany = param.Value;
              } else if(param.Key == 'PergolaDodatki'){
                this.pergolaDodatki = param.Value;
              }

              
              
            });
            if(this.ListaStopy1.length == 0){
              this.ListaStopy1 = this.ListaStopy;
              this.ListaStopyDisplay1 = this.ListaStopyDisplay;
            }
            if(this.ListaStopy2.length == 0){
              this.ListaStopy2 = this.ListaStopy;
              this.ListaStopyDisplay2 = this.ListaStopyDisplay;
            }
            if(this.ListaStopy3.length == 0){
              this.ListaStopy3 = this.ListaStopy;
              this.ListaStopyDisplay3 = this.ListaStopyDisplay;
            }
            if(this.ListaStopy4.length == 0){
              this.ListaStopy4 = this.ListaStopy;
              this.ListaStopyDisplay4 = this.ListaStopyDisplay;
            }
            
// console.log("skala 1", skala, this.getSerokosc_w_px() , this.szerokosc);

              var s1 = new Slup(0, 0, skala, this.szerokosc_slupa, this.glebokosc_slupa, this);
              s1.setRog(1);
              s1.minwys = this.WysokoscSlup[0];
              s1.maxwys = this.WysokoscSlup[1];
             
              this.ustawDomyslnieSlup(s1, 1, control.OtherParams);
              // s1.sprawdzwys();
              this.slupy.push(s1);

              var s2 = new Slup(this.szerokosc - this.glebokosc_slupa, 0, skala, this.szerokosc_slupa, this.glebokosc_slupa, this);
              s2.setRog(2);
              s2.minwys = this.WysokoscSlup[0];
              s2.maxwys = this.WysokoscSlup[1];
           
              this.ustawDomyslnieSlup(s2, 2, control.OtherParams);
              // s2.sprawdzwys();
              this.slupy.push(s2);

              var s3 = new Slup(0, this.glebokosc - this.szerokosc_slupa, skala, this.szerokosc_slupa, this.glebokosc_slupa, this);
              s3.setRog(3);
              s3.minwys = this.WysokoscSlup[0];
              s3.maxwys = this.WysokoscSlup[1];
           
              this.ustawDomyslnieSlup(s3, 3, control.OtherParams);
              // s3.sprawdzwys();
              this.slupy.push(s3);

              var s4 = new Slup( this.szerokosc - this.glebokosc_slupa, this.glebokosc - this.szerokosc_slupa, skala, this.szerokosc_slupa, this.glebokosc_slupa, this);
              s4.setRog(4);
              s4.minwys = this.WysokoscSlup[0];
              s4.maxwys = this.WysokoscSlup[1];
              
              this.ustawDomyslnieSlup(s4, 4, control.OtherParams);
              // s4.sprawdzwys();
              this.slupy.push(s4);
              this.JakiWymiar();
            
              
            
            

          
    }

    ustawDomyslnieSlup(s:Slup, numer, param:Parameter[]){
      // console.log(s, numer, param);
      var p1 = param.find(x => x.Key == "PreSlup"+numer);
      if(p1){
        if(p1.Value == 'UCHWYT'){
          s.czy_uchwyt = true;
          s.czy_widoczny = false;
          s.pre_czy_uchwyt = true;
          s.pre_czy_widoczny = false;
        } else if (p1.Value == 'BRAK'){
          s.czy_uchwyt = false;
          s.czy_widoczny = false;
          s.pre_czy_uchwyt = false;
          s.pre_czy_widoczny = false;
        }
        s.rodzaj = p1.Value;
        s.pre_rodzaj = s.rodzaj;
      }
      var p2 = param.find(x => x.Key == "PreSlupOdwodnienie"+numer);
      if(p2){
        s.czy_odwodnienie = p2.Value;
        s.pre_czy_odwodnienie = s.czy_odwodnienie;
      }
      var p3 = param.find(x => x.Key == "PreSlupStopaReg"+numer);
      if(p3){
        s.czy_stopa_regulowana = p3.Value;
        s.pre_stopa_reg = s.czy_stopa_regulowana;
      }
      var p4 = param.find(x => x.Key == "PreSlupOdwodnienie"+numer);
      if(p4){
        s.czy_odwodnienie = p4.Value;
        s.pre_czy_odwodnienie = s.czy_odwodnienie;
      }
      var p5 = param.find(x => x.Key == "PreSlupStopa"+numer);
      if(p5){
        s.stopa = p5.Value;
        s.pre_stopa = s.stopa;
      }
      var p6 = param.find(x => x.Key == "PreWysokosc"+numer);
      if(p6){
        s.czyinnawys = true;
        s.innawysokosc = p6.Value;
        
      }
      var p7 = param.find(x => x.Key == "PreWysokoscLista"+numer);
      if(p7){
        var wys = p7.Value.split("|");
        s.minwys = wys[0];
        s.maxwys = wys[1];

      }

      
    }
    InitValue(control: Control) {
      if(control.wybrany != null && control.wybrany.Value !=null && control.wybrany.Value != ''){
        var skala = this.getSkala();
        var lista = control.wybrany.Value.split(";");
        var i = 0;
        lista.forEach(slup => {
          var param = slup.split(",");
         
          //pozx = param[4];
          //pozy = param[5];
          // s.ktory_rog = param[6];
          if(i < 4){
            this.slupy[i].klient_odwodnienie = JSON.parse(param[11]);
            this.slupy[i].klient_rodzaj = JSON.parse(param[12]);
            this.slupy[i].klient_stopa = JSON.parse(param[13]);
            this.slupy[i].klient_stopa_regulowana = JSON.parse(param[14]);
            if(this.slupy[i].klient_odwodnienie){
              console.log("ustawiam odwodnienie", i, this.slupy[i].klient_odwodnienie);
              this.slupy[i].czy_odwodnienie = param[0];
            }
            if(this.slupy[i].klient_rodzaj){
              console.log("ustawiam rodzaj", i, this.slupy[i].klient_rodzaj);
              this.slupy[i].rodzaj = param[7];
              this.slupy[i].czy_uchwyt = JSON.parse(param[2]);
              this.slupy[i].czy_widoczny = JSON.parse(param[3]);
            }
            if(this.slupy[i].klient_stopa){
              console.log("ustawiam stopa", i), this.slupy[i].klient_stopa;
              this.slupy[i].stopa = param[8];
            }
            if(this.slupy[i].klient_stopa_regulowana){
              console.log("ustawiam stopareg", i, this.slupy[i].klient_stopa_regulowana);
              this.slupy[i].czy_stopa_regulowana = param[1];
            }

            
            
            
            this.slupy[i].innawysokosc = Number.parseInt(param[9]);
            this.slupy[i].czyinnawys = JSON.parse(param[10]);
            

          } else {
            
            var s = new Slup(Number.parseInt(param[4]), Number.parseInt(param[5]), skala, this.szerokosc_slupa, this.glebokosc_slupa, this);
            s.czy_odwodnienie = param[0];
            s.czy_stopa_regulowana = param[1];
            s.czy_uchwyt = JSON.parse(param[2]);
            s.czy_widoczny = JSON.parse(param[3]);
            s.setRog(0);
            s.rodzaj = param[7];
            s.stopa = param[8];
            s.minwys = this.WysokoscSlup[0];
            s.maxwys = this.WysokoscSlup[1];
            s.innawysokosc = Number.parseInt(param[9]);
            s.czyinnawys = JSON.parse(param[10]);
            // s.sprawdzwys();
            this.slupy.push(s);
          }
          

          i++;

        });


      } 
      this.JakiWymiar();
    }
    dodajSlup(){
        var skala = this.getSkala();
        // console.log("skala 2", skala, this.getSerokosc_w_px() , this.szerokosc);
        var x = 200;
        var s = new Slup(x, 0, skala, this.szerokosc_slupa, this.glebokosc_slupa, this);
        
        s.minwys = this.WysokoscSlup[0];
        s.maxwys = this.WysokoscSlup[1];
        this.slupy.push(s);
        this.JakiWymiar();
    }
    dodajUchwyt(){
        
    }
    JakiWymiar() {
        this.slupy.forEach(s => {
            s.JakiWymiar(this);
        });
        this.ustawWneki();
      }
    Edytuj(i){
        this.edytuj = i;
    }
    usunSlup(id){
      
        
        if(id > 3){
          this.edytuj = -1;
          this.slupy.splice(id, 1);
        } else {
          this.slupy[id].czy_widoczny = false;
          this.slupy[id].czy_uchwyt = false;
        }
    }
    przywrocSlup(id){
      
      
      if(id < 4) {
        this.slupy[id].czy_widoczny = true;
        this.slupy[id].czy_uchwyt = false;
      } else {
        this.slupy[id].czy_widoczny = true;
        this.slupy[id].czy_uchwyt = false;
      }
      
  }
  ustawUchwyt(id){
    if(id < 4) {
      this.slupy[id].czy_widoczny = false;
      this.slupy[id].czy_uchwyt = true;
    } else {
      this.slupy[id].czy_widoczny = false;
      this.slupy[id].czy_uchwyt = true;
    }
    
}
    poprawSlupy(){
        this.slupy.forEach(s=> {
            if(s.pozx < 0){
                s.pozx = 0;
            }
            if(s.pozy < 0){
                s.pozy = 0;
            }
            if(s.pozx > this.szerokosc - this.szerokosc_slupa){
                s.pozx = this.szerokosc - this.szerokosc_slupa;
            }
            if(s.pozy > this.glebokosc - this.glebokosc_slupa){
                s.pozy = this.glebokosc - this.glebokosc_slupa;
            }

            if(s.pozx> 0 && s.pozy > 0){
                if(s.pozx ==  this.szerokosc - this.szerokosc_slupa){

                } else if (s.pozy == this.glebokosc - this.glebokosc_slupa){

                } else {

                }
            }

        });
    }
    getWidth(){
        return this.getSerokosc_w_px();
    }

    getHeight(){
        return  this.getSerokosc_w_px()/ this.szerokosc * this.glebokosc;
    }
    getColumnWidth(){
        return  this.getSerokosc_w_px() / this.szerokosc * this.szerokosc_slupa;
    }
    getColumnHeight(){
        return  this.getSerokosc_w_px() / this.szerokosc * this.glebokosc_slupa;
    }

    getInnerWidth(){
        return  this.getSerokosc_w_px() / this.szerokosc * (this.szerokosc - 2*this.glebokosc_slupa);
    }
    getInnerHeight(){
        return  this.getSerokosc_w_px() / this.szerokosc * (this.glebokosc - 2*this.glebokosc_slupa);
    }
    getLeft(){
        return this.getSerokosc_w_px() / this.szerokosc * this.glebokosc /2 - 50;
    }
}